import model from "@js/model.js";
import { addQueryStringValues, minsToMills } from "@helpers/utils.js"
import { MaintenanceText, MaintenanceTitle } from "@lang/maintenance.js";

const TIMEOUT_MINS_OFF = 5;
const TIMEOUT_MINS_ACTIVE = 1;

let timeout = 0;
let refreshTime = 0;

export default {
	initMaintenanceSetup(){
		if(!model.urls.maintenance?.active || typeof model.urls.maintenance?.config !== 'string') return;

		model.pushLang({ MaintenanceText, MaintenanceTitle });
		sendReq.call(this);
	},
}

function sendReq(){
	this.webServiceSend({
		dataType: 'json',
		url: model.urls.maintenance.config,
		type: 'GET',
		cache: false,
		global: false,
		success: (data) => handleMaintenance.call(this, data),
	});
	//console.log('maintenance req sent');
}

function sendReqTimeout(mins){
	refreshTime = minsToMills(mins);

	clearTimeout(timeout);
	timeout = setTimeout(() => sendReq.call(this), refreshTime);
}

function handleMaintenance(data){
	var msgFrom = new Date(data.msgFrom);
	var offline = {
		dateFrom: new Date(data.offline.dateFrom),
		dateTo: new Date(data.offline.dateTo),
	};

	var now = Date.now();

	if(offline.dateTo.getTime() < now) {
		sendReqTimeout.call(this, TIMEOUT_MINS_OFF);
		return;
	}

	if(offline.dateFrom.getTime() < now && offline.dateTo.getTime() > now){
		window.location.replace(addQueryStringValues(model.urls.maintenance.path, {
			offlineUntil: data.offline.dateTo,
			lang: model.lang,
		}));
		return;
	}

	if(msgFrom.getTime() < now){
		sendReqTimeout.call(this, TIMEOUT_MINS_ACTIVE);

		if(sessionStorage.getItem('maintenance-notified') !== 'true'){
			let dateFrom = new Date(offline.dateFrom).toLocaleString();
			let dateTo = new Date(offline.dateTo).toLocaleString();
	
			this.showNotification({
				title: model.getStr('MaintenanceTitle'),
				text: model.getStr('MaintenanceText').replace('{dateFrom}', `<b>${dateFrom}</b>`).replace('{dateTo}', `<b>${dateTo}</b>`),
				setFirst: true,
				closeDisabled: true,
				afterTerminate: () => sessionStorage.setItem('maintenance-notified', true),
			});
		}

		return;
	}

	sendReqTimeout.call(this, TIMEOUT_MINS_OFF);
}