import model from "@js/model.js";
import { isObject, toArray, getParameterByName, arrayFirst } from "@helpers/utils.js"
import Enum from "@shared/enum.js";

import dynamicImportLoad from "@helpers/dynamic-import-load.js";
import { reactive } from "vue";

let paymentData = {
	window: null,
	interval: null,
	transId: null,
	methodId: null,
	methodIdNoPrefix: null,
	type: null,
	hasIFrame: false,
	apiPropName: null,
	providerType: null,
	amount: null,
	logoUrl: null,
};

export default {
	initCashier(){
		model.observables.mobileWindowFrame = false;
		// model.observables.lastSoarPayTxState = null;

		model.on('LogoutComplete', () => model.cashierExternalTransData = null);

		model.cashierPaymentPageStatus = reactive({});
		model.cashierSelectedProvider = {};

		model.soarPayTxStateErrors = ['CANCELLED', 'FAILED', 'INCONSISTENT'];

		model.on('ModalClosed', e => {
			if(e?.modal?.uiContext !== Enum.UIContexts.CASHIER) return;
			this.startCashierBusiness(false).then(module => module.resetPaymentReceiptCount.call(this));
			clearTimeout(model.retryReceiptTimeout);
		});

		if(typeof model.hideCMGameLoad !== 'boolean') model.hideCMGameLoad = false;
		if(typeof model.pendingPayoutEnabled !== 'boolean') model.pendingPayoutEnabled = true;
		if(typeof model.cashierCreditsToggle !== 'boolean') model.cashierCreditsToggle = true;
		if(typeof model.cashierWageringOrder !== 'boolean') model.cashierWageringOrder = false;

		model.minPremierCashierCredits = model.minPremierCashierCredits || 1000;

		model.observables.hostedPagesScriptLoaded = false;

		var excludedMethods = model.paymentConfig.excludedMethods;
		if(isObject(excludedMethods)){
			if(isObject(excludedMethods.payin)){
				for(var cCode in excludedMethods.payin) excludedMethods.payin[cCode] = toArray(excludedMethods.payin[cCode], true);
			}
			if(isObject(excludedMethods.payout)){
				for(var cCode in excludedMethods.payout) excludedMethods.payout[cCode] = toArray(excludedMethods.payout[cCode], true);
			}
		}

		model.on('ServerResponseSuccess', (e) => {
			var action = e.action;
			switch(action.actionType){
				case "PaymentTransactionList":
					handlePaymentTransactionList.call(this, action);
					break;
				case "InitPayIn":
				case "InitAndExecutePayOut":
				case "InitAndExecutePayIn":
					handleInitPayInOut.call(this, action);
					break;
				case "ConfirmPayout":
					this.showClientMessage(action);
					break;
			}
		});

		model.on('PostMessageReceived', (e) => {
			var event = e.event;
			if(!event.data)
				return;

			if(model.activeGames && event.data.type == 'UpdateCashAfterBet'){
				var gameGroup = this.getGameGroupByID(model.activeGames['egoe'].id);
				if(!gameGroup)
					return;

				model.dispatchEvent('SetCMeterVal', {
					id: gameGroup == Enum.GameGroups.CasinoSlotGames ? Enum.GameGroups.CasinoSlotGames : Enum.GameGroups.CasinoTableGames,
					cash: event.data.cash,
				});
			}
		});

		var paymentType = getParameterByName('paymentType');
		var paymentSuccess = getParameterByName('paymentSuccess');
		var displayMessage = getParameterByName('displayMessage');
		if(paymentType && paymentSuccess && displayMessage){
			setTimeout(() => this.showPaymentResponse(paymentType, paymentSuccess, displayMessage), 0);
		}

		if(model.view == Enum.Views.CASHIER){
			model.on('LogoutComplete', 'LoginComplete', (e) => {
				this.goTo(Enum.UIContexts.CASHIER);
				e.dispose();
			});
			
			var t = null;
			var prevContext = model.getCurrContext();
			model.on('UIContextUpdated', () => {
				if (prevContext === model.getCurrContext()) return;

				clearTimeout(t);
				t = setTimeout(() => !this.anyModals() && window.history.back(), 100);
				prevContext = model.getCurrContext();
			});
		}

		let paymentRedirForceVal = sessionStorage.getItem('payment-redirect-force-deposit');
		if(paymentRedirForceVal){
			model.on('LoginComplete', e => {
				e.dispose();
				this.whenAppReady(() => this.getPaymentRedirData() && this.goTo(paymentRedirForceVal));
			});
		}

		window.addEventListener('storage', e => {
			if(e.key === 'close-payment-window' && paymentData.window){
				paymentData.window.close();
				paymentData.window = null;
				model.dispatchEvent('ClosePaymentWindow');
			}
		});

		model.on('PostMessageReceived', e => {
			let event = e.event;
			if(event.data.type != 'PaymentResponse' || !paymentData.hasIFrame || !paymentData.transId) return;
			this.showPaymentReceipt(paymentData.transId, false);
		});

		model.on('ClosePaymentWindow', () => {
			if(!paymentData?.transId) return;
			clearInterval(paymentData.interval);
			this.showPaymentReceipt(paymentData.transId, false);
			localStorage.removeItem('soarpay-redir-close-window');
		});
	},
	getLastProviderID(targetPaymentType){
		var list = model.paymentTransactionList;

		if(model.soarPayConfig?.methods?.general){
			model.legitPPIDs = [];

			Object.keys(model.soarPayConfig.methods.general).forEach(key => {
				let providerConfig = model.soarPayConfig.methods.general[key];
				if(Array.isArray(providerConfig.ppId)) providerConfig.ppId.forEach(ppId => !model.legitPPIDs.includes(ppId) && model.legitPPIDs.push(ppId));
			});
		}

		if(!Array.isArray(list)) return;

		return list.find(x => 
			(x.type + '').toLowerCase() === targetPaymentType.toLowerCase() && 
			(x.status + '').toLowerCase() == 'processed' && 
			(model.legitPPIDs ? model.legitPPIDs.includes(x.ppId) : true))?.ppId;
	},
	savePaymentRedirData(data){
		localStorage.setItem(`payment-redirect-data_${model.userProfile?.userName}`, data);
	},
	getPaymentRedirData(){
		return localStorage.getItem(`payment-redirect-data_${model.userProfile?.userName}`);
	},
	removePaymentRedirData(){
		localStorage.removeItem(`payment-redirect-data_${model.userProfile?.userName}`);
	},
	startCashierBusiness(global){
		return this.getBusiness('__cashierBusiness', () => import("../business/cashier-business.js"), global);
	},
	loadSafeChargeJS(){
		return new Promise(resolve => this.getScript(model.urls.safeCharge, false).then(() => resolve()));
	},
	loadHostedPagesScript(global){
		if(typeof global !== 'boolean') global = true;
		return new Promise(resolve => this.getScript(model.urls.piqHostedFieldsScript, global).then(() => resolve()));
	},
	async getSoarPayAPIs(){
		let module = await this.startCashierBusiness();
		return await module.getSoarPayAPIs.call(this);
	},
	async getSoarPayConfig(){
		let module = await this.startCashierBusiness();
		return await module.getSoarPayConfig.call(this);
	},
	async getAllPaymentMethods(){
		let module = await this.startCashierBusiness(false);
		return await module.getAllPaymentMethods.apply(this, arguments);
	},
	processPaymentReceipt(){
		this.startCashierBusiness().then(module => module.processPaymentReceipt.apply(this, arguments));
	},
	async updateSoarPayAccounts(){
		let module = await this.startCashierBusiness();
		return await module.updateSoarPayAccounts.apply(this, arguments);
	},
	async sendPaymentIQFormSubmit(){
		let args = Array.prototype.slice.call(arguments);
		args.push(paymentData);
		this.startCashierBusiness().then(module => module.sendPaymentIQFormSubmit.apply(this, args));
	},
	handlePaysafeCC(methodModel, onSuccess, onError){
		this.paysafeCC.handlePayment.apply(this.paysafeCC, arguments);
	},
	loadPaysafeScript(callback){
		dynamicImportLoad(() => import("../business/payment/paysafe-cc-business.js")).then(module => {
			this.paysafeCC = this.paysafeCC || new module.default(this.model, this);
			this.paysafeCC.loadScript(callback);
		});
	},
	removeCreditCard(ccId){
		this.sendRequest('UserDataManipulation', {
			type: 'RemoveCreditCard',
			creditCardId: ccId,
		});
	},
	showPaymentResponse(){
		this.startCashierBusiness().then(module => module.showPaymentResponse.apply(this, arguments));
	},
	sendDepositRequest(){
		this.startCashierBusiness().then(module => module.sendDepositRequest.apply(this, arguments));
	},
	sendWithdrawRequest(){
		this.startCashierBusiness().then(module => module.sendWithdrawRequest.apply(this, arguments));
	},
	sendCancelWithdrawRequest (params) {
		params.type = 'CancelProcess';
		this.sendRequest("ConfirmPayout", params, () => this.sendKeepAliveRequest());
	},
	sendConvertBonusPointsRequest(){
		this.sendRequest("InitAndExecutePayIn", {
			"ppId": '63',
			"amount": model.mainAccount.amountToConvert,
			"actionUser": '0'
		}, () => this.sendKeepAliveRequest());
	},
	sendPaymentTransactionList(params, global) {
		return new Promise(resolve => {
			if(!isObject(params)) params = {};
	
			params.listLength = 5000;
	
			this.sendRequest('PaymentTransactionList', params, () => resolve(), {global});
		});
	},
	whenMobileWFReady(callback){
		model.observables.mobileWindowFrame = true;
		this.whenComponentReady('MobileWindowFrame').then(() => this.whenAppReady(callback));
	},
	showPaymentReceipt(transactionId, waitingInputUI){
		this.processPaymentReceipt({
			transactionId,
			methodId: paymentData.methodId,
			methodIdNoPrefix: paymentData.methodIdNoPrefix,
			paymentType: paymentData.type,
			apiPropName: paymentData.apiPropName,
			providerType: paymentData.providerType,
			amount: paymentData.amount,
			logoUrl: paymentData.logoUrl,
			logoUrlLoadingSection: paymentData.logoUrlLoadingSection,
			waitingInputUI,
		});
	},
}

function handlePaymentTransactionList(action) {
	var main = arrayFirst(action.messages.message, msg => msg.type == 'Main' && msg.paymentList);
	model.paymentTransactionList = main?.paymentList?.response?.transactions?.trans ? toArray(main.paymentList.response.transactions.trans) : null;
	model.dispatchEvent("PaymentTransactionListLoaded");
}

function handleInitPayInOut(action){
	this.startCashierBusiness().then(module => {
		module.handleInitPayInOut.call(this, action);
	});
}