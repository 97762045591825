import model from "@js/model.js";
import { isObject, isUndefined } from "@helpers/utils.js"


export default {
	initImages(){
		EGOE.handleImageError = img => $(img).hide();
		this.addErrorHandlerToImages();
	},
	addErrorHandlerToImages($imgs){
		if(isUndefined($imgs) || $imgs.length == 0) $imgs = $('img');
		$imgs.each((__, img) => $(img).attr('onError', 'EGOE.handleImageError(this)'));
	},
	preloadImgAsync(params){
		return new Promise(resolve => {
			if(typeof params.success === 'function'){
				let oldSuccess = params.success;
				params.success = (src, target) => {
					oldSuccess(src, target);
					resolve({src, target});
				}
			}
			else params.success = (src, target) => {
				resolve({src, target});
			};
	
			this.preloadImg(params);
		});
	},
	preloadImg(params){
		if(typeof params.webp !== 'boolean') params.webp = true;

		var image = new Image();
		image.crossOrigin = 'Anonymous';
		if(params.webp) image.src = this.changeExtToWebP(params.imgUrl);
		else image.src = params.imgUrl;
		image.onload = e => {
			if(params.$element){
				if(params.$element.prop('tagName') == 'IMG')
					params.$element.attr('src', e.currentTarget.src).attr('onError', 'EGOE.handleImageError(this)');
				else
					params.$element.css({'background-image': 'url(' + e.currentTarget.src + ')'});
			}

			params.success && params.success(e.currentTarget.src, e.currentTarget);
		}

		image.onerror = e => {
			if(typeof params.error === 'function') params.error(e);
			else{
				EGOE.handleImageError(this);
				params.success && params.success();
			}
		}
	},
	changeExtToWebP: (function(){
		var formatExceptions = ['svg'];
		return function(src){
			if(isObject(src)){
				let key = Object.keys(src).find(key => model.webpSupported ? key.includes('.webp') : !key.includes('.webp'));
				src = src[key];
			}

			if(!src) return null;
			if(model.webpSupported && !formatExceptions.includes(src.substr(src.lastIndexOf('.') + 1).toLowerCase())) return src.replace(/\.(jpg|png)/gi, '.webp');
			return src;
		};
	}()),
}