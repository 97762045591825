import model from "@js/model.js";
import { reactive } from "vue";

export default {
	initModal(){
		model.modalRevampedUI = model.isUI('FUND') || model.isApp;

		this.activeModals = [];
		this.modalCallback;

		model.observables.modals = [];
		model.observables.currContext = null;
		model.observables.currSubContext = null;

		this.registeredModals = reactive({value: []});
	},
	modalBusiness(){
		return this.getBusiness('__modalBusiness', () => import("../business/modal-business.js"));
	},
	anyModals(){
		return this.activeModals.length > 0;
	},
	getLastModal(){
		if(!this.anyModals()) return null;
		return this.activeModals[this.activeModals.length - 1];
	},
	getActiveModal(){
		var modal = this.getLastModal();
		return modal ? (modal.shown ? modal : null) : null;
	},
	closeActiveModal(){
		var modal = this.getActiveModal();
		if(modal) modal.shown = false;
	},
	loadPrevModal(){
		if(!this.anyModals()) return;

		var removedModal = this.activeModals.pop();
		this.updateModalUIContext();
		this.removeModal(removedModal);

		if(!this.anyModals()) return;

		var lastModal = this.activeModals[this.activeModals.length - 1];
		lastModal.shown = true;
		model.dispatchEvent('GoTo', {
			uiContext: lastModal.uiContext,
			loadPrev: true,
		});
	},
	removePrevModals(pendingModal){
		var activeModal = this.getActiveModal();
		while(this.anyModals()){
			var removedModal = this.activeModals.pop();
			if(activeModal !== removedModal && (!pendingModal || pendingModal !== removedModal)) this.removeModal(removedModal);
		}
		this.updateModalUIContext();
	},
	closeAllModals(){
		var activeModal = this.getActiveModal();
		this.removePrevModals();
		activeModal && (activeModal.shown = false);
	},
	addModal(modal){
		model.observables.modals.push(modal);
		if(modal && modal.uiContext){
			var callback = this.modalCallback;
			if(typeof callback === 'function'){
				callback(modal);
				delete this.modalCallback;
			}
		}
	},
	removeModal(modal){
		if(!model.observables.modals.includes(modal)) return;
		model.observables.modals.splice(model.observables.modals.indexOf(modal), 1);
		var modalInfo = this.registeredModals.value.find(item => item.compName == modal.uiContext);
		if(modalInfo) modalInfo.obs = false;
	},
	goTo(){
		this.modalBusiness().then(module => {
			module.goTo.apply(this, arguments);
		});
	},
	goToNoReq() {
		this.modalBusiness().then(module => {
			module.goToNoReq.apply(this, arguments);
		});
	},
	loadModalByContext(){
		this.modalBusiness().then(module => {
			module.loadModalByContext.apply(this, arguments);
		});
	},
	updateModalUIContext(){
		var targetModal = this.getLastModal();
		var newContext = targetModal ? targetModal.uiContext : null;
	
		model.currentContext = newContext;
		model.observables.currContext = model.currentContext;
		
		if(!model.currentContext) model.setSubContext(null);
		else{
			if(targetModal.subContext) model.setSubContext(targetModal.subContext);
			else if(targetModal.hasPages){
				var targetPage = targetModal.pages.find(item => item.active);
				if(targetPage) model.setSubContext(targetPage.uiContext);
				else model.setSubContext(null);
			}
			else model.setSubContext(null);
		}
	
		model.dispatchEvent("UIContextUpdated");
	}
}