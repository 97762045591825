<script>
import { getKeyByValue } from "@helpers/utils.js"

import vm from "@js/root-view-model.js";
import asyncLoad from "@components/async-load-view.vue";

export default {
	methods: { getKeyByValue },
    extends: vm,
    components: { asyncLoad },
}
</script>

<template>
    <div
        :data-active-page="(model.observables.activePage || '').toLowerCase()"
        :data-lang="$root.lang"
        :data-loader-shown="$root.preloaderDisplayed"
        :data-notification-opened="model.observables.notifOpened"
        :data-mobile-sidemenu-active="$root.mobileSideMenuActive"
        :data-wof-displayed="model.observables.wofDisplayed || Boolean(model.observables.selectedMiniGame)"
        :data-view="(getKeyByValue(Enum.Views, model.view) + '').toLowerCase()"
        :data-cookie-msg-shown="model.observables.cookieMsgShown"
        :data-reg-confirm-shown="model.observables.regConfirmShown"
        :data-subnav-plus-placeholder-slim="(model.observables.activePage === Enum.Pages.WEBRES ? !model.observables.webresShowNav : false)"
        :data-game-frame-displayed="model.observables.gameFrameDisplayed"
        :class="{
            'mobile': model.isMobile,
        }"
        :data-subnav-pl-slim="model.observables.subNavPlaceholderSlim"
    id="application-root">
        <template v-if="!model.isApp ? !$root.isMobile : true">
            <template v-for="gameBusiness in model.observables.gameBusinesses">
                <template v-if="gameBusiness.game.loaded">
                    <async-load 
                        :loader="{
                            FOUR_SG: () => import('@components/gameplay/gameplay-4sg.vue'),
                            SKY: () => import('@components/gameplay/gameplay-sky.vue'),
                            FUND: () => import('@components/gameplay/gameplay-fund.vue'),
                        }"
                        :bindings="{gameBusiness}"
                        :name="'gameplay'"
                        :check-for-viewport="false"
                        :global-loader="true"
                        :max-page-height="true"
                    />
                </template>
            </template>
        </template>

        <template v-if="model.isView(Enum.Views.MAIN) && model.observables.selectedMiniGame">
            <async-load
                :loader="() => import('@components/mini-game-view.vue')"
                :name="'mini-game'"
                :global-loader="true"
                :under-condition="() => model.observables.selectedMiniGame"
            />
        </template>

        <async-load
            :loader="{
                FOUR_SG: () => import('@components/notification-handler/notification-handler-4sg.vue'),
                FUND: () => import('@components/notification-handler/notification-handler-fund.vue'),
            }"
            :name="'notification-handler'"
            :global-loader="true"
            :under-condition="() => model.observables.notifHandlerLoaded"
        />

        <upper-notification />
        <network-error />

        <async-load 
            :loader="() => import('@components/iframe-popup-view.vue')"
            :name="'iframe-popup'"
            :global-loader="true"
            :under-condition="() => model.observables.iframePopupLoaded"
        />

        <async-load 
            :loader="() => import('@components/mobile-window-frame-view.vue')"
            :name="'mobile-window-frame'"
            :global-loader="true"
            :under-condition="() => model.observables.mobileWindowFrame"
        />

        <div :class="{'hidden': !$root.preloaderDisplayed || $root.networkErrDisplayed}" id="preloader-main" class="loader-container">
            <preload-icon />
        </div>

        <div v-img-parallax id="background-container" :style="{'background-image': `url(${$root.changeExtToWebP(model.urls.assets.background)})`}"></div>

        <div id="app" :class="{'mobile': model.isMobile}">
            <app />
        </div>

        <template v-for="modal in $root.modals" :key="modal.id">
            <template v-if="modal.obs">
                <component :is="modal.compName"></component>
            </template>
        </template>

        <!-- <template v-if="model.isMobile"> -->
            <div id="modal-outer-headers"></div>
        <!-- </template> -->
    </div>
</template>