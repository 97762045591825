export const GameplayMGANotice = {
	'en': '18+ Play responsibly. Gambling can be addictive. Visit {resp-gaming} page.',
	'es': '18+ Juega responsablemente. El juego puede ser adictivo. Visita la página de {resp-gaming}.',
}

export const WOF_Promo_Cancel_Confirmation_Msg = {
	'en': 'Are you sure you want to cancel this promotion?',
}

export const NationalIdLabel = { 
	"en": "National ID"
}

export const AccountNumber = { 
	"en": "Account Number",
	"es": "Número Interbancario"
}

export const nip = { 
	"en": "Nip"
}

export const CryptoCurrencySelectLabel = { 
	"en": "Select Cryptocurrency"
}

export const SportsBettingRules = {  
	"en": "Sports Betting Rules"
}

export const ComingSoonLabel = { 
	"en": "próximamente"
}

export const SearchResultsLabel = { 
	"en": "Search results",
	"es": "Resultados de búsqueda"
}

export const NoDataFoundLabel = { 
	"en": "No data found",
	"es": "Datos no encontrados"
}

export const ShareLabel = { 
	"en": "Share",
	"es": "Compartir"
}

export const PlayingInDemoText = { 
	"en": "YOU ARE PLAYING IN DEMO MODE",
	"es": "ESTÁS JUGANDO EN MODO DEMO"
}

export const SelectYourCoutryLabel = {
	"en": "Select your country: ",
	"pe": "Selecciona tu pais: "
}

export const GoBackHomeLabel = {
	"en": "Go Back Home",
	"es": "Volver al Ιnicio"
}

export const PageNotFoundText = {
	"en": "NO PAGE HERE, JUST EMPTY SPACE.",
	"es": "NINGUNA PÁGINA,<br>SOLO EL ESPACIO VACÍO"
}

export const ProvidersLabel = { 
	"en": "Providers",
	"es": "Proveedores"
}

export const GoBackLabel = { 
	"en": "Go Back",
	"es": "Volver Atrás"
}



export const MoreLabel = { 
	"en": "More",
	"es": "Más"
}

export const SwipeUp = {
	"en": "Swipe Up",
	"es": "IR ABAJO"
}

export const ScrollDown = {
	"en": "Scroll Down",
	"es": "IR ABAJO"
}

export const LaunchLabel = {
	"en": "Launch",
	"es": "Lanzamiento"
}





export const AboutFundalor = {
	"en": "About Fundalor",
	"es": "Sobre Fundalor"
}





export const FundalorPicks = {
	"en": "Fundalor Picks",
	"es": "SELECCIÓN DE FUNDALOR"
}

export const PointsLabel = {
	"en": "Points",
	"es": "Puntos"
}

export const ParticipatingGamesLabel = {
	"en": "Participating Games",
	"es": "JUEGOS PARTICIPANTES"
}

export const CallFundLabel_IntEngLang = {
	"en": "{0}<br>(International, English language)<br><small>Give us a call and we will get back to you shortly.</small> ",
	"es": "{0}<br>(International, English language)<br><small>Llámenos y te responderemos muy pronto.</small> "
}

export const CallFundLabel = { 
	"en": "{0}<br><small>Give us a call and we will get back to you shortly.</small> ",
	"es": "{0}<br><small>Llámenos y te responderemos muy pronto.</small> "
}

export const AffiliateFundDescr = { 
	"en": "{0}<br><small>Are you an affiliate? Drop us an email.</small><br>",
	"es": "{0}<br><small>¿Eres afiliado? Envíenos un correo electrónico.</small><br>"
}

export const EmailFundDescr = { 
	"en": "{0}<br><small>Send us an email and we will be more than happy to help.</small><br>",
	"es": "{0}<br><small>Envíanos un correo electrónico y estaremos encantados de ayudarte.</small><br>"
}

export const LiveSupportDescr = { 
	"en": "{0}<br><small>Open the live chat on the website and talk to a representative available 24/7</small>",
	"es": "{0}<br><small>Abre el chat en vivo en el sitio web y habla con un representante disponible las 24 horas, los 7 días de la semana</small>"
}

export const LiveSupport = {
	"en": "Live Support",
	"es": "Ayuda en Vivo"
}

export const WhatsApp = {
	"en": "WhatsApp"
}

export const FundContactUsDescrFAQLink = {
	"en": "FAQ",
	"es": "las preguntas frecuentes"
}

export const FundContactUsDescr = {
	"en": "A gigantic network of communications has been developed on Fundalor in order to provide help and answer any questions that inhabitants may have. Check out the {faq-link} or contact one of our representatives who are available 24/7. ",
	"es": "En Fundalor se ha desarrollado una gigantesca red de comunicaciones con el fin de brindar ayuda y dar respuesta a las dudas que puedan tener los habitantes. Consulta {faq-link} o comunícate con uno de nuestros representantes que están disponibles las 24 horas del día, los 7 días de la semana."
}

export const AboutUs = {
	"en": "About Us",
	"es": "Sobre nosotros"
}

export const Instantly = {
	"en": "Instantly",
	"es": "Instantáneo"
}

export const LimitsPerTrans = {
	"en": "LIMITS PER TRANSACTION",
	"es": "LÍMITES POR TRANSACCIÓN"
}

export const ProcessingTimeLabel = {
	"en": "Processing Time",
	"es": "TIEMPO DE PROCESAMIENTO"
}

export const FeeLabel = {
	"en": "Fee",
	"es": "TARIFA"
}

export const PaymentMethodLabel = {
	"en": "Payment Method",
	"es": "MÉTODOS DE PAGO"
}

export const DepositsLabel = {
	"en": "Deposits",
	"es": "Depósitos"
}

export const WithdrawalsLabel = {
	"en": "Withdrawals",
	"es": "Retiros"
}

export const PaymentMethodsLabel = {
	"en": "Payment Methods",
	"es": "Métodos De Pago"
}

export const FundPaymentPage = {
	"en": "As you might have already guessed, payments on an advanced exoplanet like Fundalor are being carried out in light-speed. Funeers - the engineers preserving the Fun Source - are making sure that everything is up and running flawlessly, so no matter what currency or provider you select, your transaction is just one click away.",
	"es": "Como ya habrás adivinado, los pagos en un exoplaneta avanzado como Fundalor se están realizando a la velocidad de la luz. Funeers, los ingenieros que preservan Fuente de Diversión, se aseguran de que todo funcione sin problemas, por lo que no importa qué moneda o proveedor selecciones, tu transacción está a solo un clic de distancia."
}

export const FundFooterLinksDescr = {
	"FUND_COM": {
		"en": "Fundalor is the home planet of a new iGaming era, a fairer world where the inhabitants, called Fundaloris, are living in harmony having access to a huge source of fun without restrictions. Fundaloris enjoy fascinating offers that come with absolutely no wagering requirements on more than 3000 games from top-notch game providers like Play’n GO, Red Tiger, Netent, Games Global and Evolution.",
		"es": "Fundalor es el planeta de origen de una nueva era de iGaming, un mundo más justo donde los habitantes, llamados Fundaloris, viven en armonía y tienen acceso a una enorme fuente de diversión sin restricciones. Los Fundaloris disfrutan de ofertas fascinantes que no requieren absolutamente ningún requisito de apuesta en más de 3000 juegos de proveedores de juegos de primer nivel como Play'n GO, Red Tiger, Netent, Games Global y Evolution."
	},
	"FUND_MX": {
		"es": "Fundalor es el planeta de origen de una nueva era de iGaming, un mundo más justo donde los habitantes, llamados Fundaloris, viven en armonía y tienen acceso a una enorme fuente de diversión sin restricciones. Los Fundaloris disfrutan de ofertas fascinantes que no requieren absolutamente ningún requisito de apuesta en más de 2000 juegos de proveedores de juegos de primer nivel como Pragmatic Play, Play'n GO, Playson, Red Tiger, Netent y Evolution."
	}
}

export const GamesLabel = {
	"en": "Games",
	"es": "Juegos"
}

export const SaveLabel = {
	"en": "Save",
	"es": "Guardar"
}

export const OffDefault = {
	"en": "Off (default)",
	"el": "Off (προεπιλογή)",
	"de": "Aus (Standard)",
	"es": "Desactivado (predeterminado)"
}

export const OptionalLabel = {
	"en": "Optional",
	"el": "Προαιρετικό",
	"es": "Opcional",
	"de": "Optionell"
}

export const ContactSupportLabel = {
	"en": "Contact support",
	"el": "Υποστήριξη πελατών",
	"es": "Contactar Soporte",
	"de": "Kontaktiere Kundendienst"
}

export const ContactSupportShortLabel = {
	"en": "Support",
	"es": "Soporte",
}

export const SelfAssessmentTool = {
	"en": "Self Assessment",
	"el": "Αυτο-έλεγχος εθισμού",
	"es": "Autoevaluación",
	"de": "Selbsteinschätzungs-Tool"
}

export const TCChangesTrigger = {
	"en": "Highlight changes from version {ver} dated {date}.",
	"es": "Resalta los cambios de la versión {ver} con fecha {date}."
}

export const RemoveLabel = {
	"en": "Remove",
	"el": "Αφαιρώ",
	"de": "Entfernen",
	"es": "Eliminar"
}

export const BackLabel = {
	"en": "Back",
	"el": "Πίσω",
	"de": "Zurück",
	"es": "Atrás"
}



export const ActiveLabel = { 
	"en": "Active",
	"el": "Ενεργό",
	"de": "Aktiv",
	"es": "Activo"
}

export const RecentlyPlayedLabel = {
	"en": "Recently Played",
	"es": "JUGADO RECIENTEMENTE"
}

export const FavoriteGamesLabel = {
	"en": "Favourites",
	"el": "ΑΓΑΠΗΜΕΝΑ",
	"de": "Beliebteste Spiele",
	"es": {
		"FOUR_SG": "Juegos favoritos",
		"FUND": "Favoritos"
	}
}



export const OutdatedOS = {
	"en": "The game cannot be launched, because your operating system (OS) is out of date and not supported by our platform. Please update the OS of your device and try again.",
	"el": "Το παιχνίδι δεν μπορεί να ξεκινήσει, γιατί το λειτουργικό σου σύστημα (OS) δεν είναι αναβαθμισμένο και δεν υποστηρίζεται από την πλατφόρμα μας. Αναβάθμισε το λειτουργικό σύστημα (OS) της συσκευής σου και προσπάθησε ξανά.",
	"de": "Das Spiel kann nicht gestartet werden, weil dein Betriebssystem (OS) veraltet ist und von unserer Plattform nicht unterstützt wird! Bitte aktualisiere das Betriebssystem deines Geräts und versuche es erneut.",
	"es": "El juego no se puede iniciar porque tu sistema operativo (SO) está desactualizado y nuestra plataforma no lo admite. Actualiza el sistema operativo de tu dispositivo e intente nuevamente."
}

export const WallOfFame = { 
	"en": "Wall of Fame",
	"de": "Tafel der Sieger",
	"tr": "Şöhret Duvarı",
	"es": "Muro de la fama",
	"el": "ΠΑΝΘΕΟΝ ΝΙΚΗΤΩΝ"
}

export const RespGamingLabel = { 
	"en": "Responsible Gaming",
	"el": "Υπεύθυνο Παιχνίδι",
	"de": "Verantwortungsbewusstes Spielen",
	"tr": "Sorumlu Oyun",
	"es": "Juego Responsable"
}

export const RespGamingNotifText = {
	en: 'Do you want to setup your gambling limits now?',
	es: '¿Quieres configurar tus límites de juego ahora?',
};

export const SearchNormalLabel = {
	"en": "Search",
	"es": "Buscar"
}

export const SearchLabel = {
	"en": "Search...",
	"es": "Buscar..."
}

export const SearchByNameLabel = {
	"en": "Search games or providers...",
	"el": "Ψάξε παιχνίδια ή παρόχους...",
	"de": "Suche nach Spielen oder Spieleanbietern...",
	"es": "Buscar juegos o proveedores..."
}

export const LiveChatLabel = {
	"en": "LIVE CHAT SUPPORT",
	"el": "LIVE ΥΠΟΣΤΗΡΙΞΗ",
	"de": "LIVE-CHAT-SUPPORT",
	"tr": "CANLI SOHBET DESTEĞİ",
	"es": "Ayuda en línea"
}

export const ClearLabel = {
	"en": "Clear",
	"el": "Καθάρισμα",
	"de": "Löschen",
	"tr": "Temizle",
	"es": "Borrar"
}







export const AcceptLabel = {
	"en": "Accept",
	"el": "Αποδέχομαι",
	"de": "Akzeptieren",
	"tr": "Kabul Et",
	"es": "Aceptar"
}





export const CookiePolicyLabel = {
	"en": "Cookies Policy",
	"el": "Πολιτική Cookies",
	"de": "Cookie-Richtlinie",
	"tr": "Çerez Politikası",
	"es": "Política de Cookies"
}

export const blueberrySMS_Text = { 
	"en": "Please enter the SMS validation code in the input field below.",
	"el": "Βάλε τον κωδικό επαλήθευσης SMS στο πεδίο εισαγωγής παρακάτω.",
	"de": "Gib bitte den SMS-Validierungscode in das Eingabefeld weiter unten ein.",
	"es": "Introduce el código de validación de SMS en el campo de entrada a continuación.",
	"se": "Vänligen ange SMS-verifieringskoden i inmatningsfältet nedan.",
	"tr": "Lütfen aşağıdaki alana SMS doğrulama kodunu girin."
}



export const KYCPolicy = {
	"en": "KYC Policy",
	"el": "Πολιτική KYC",
	"es": "Política de KYC",
	"de": "KYC Bestimmungen"
}

export const AMLPolicy = {
	"en": "AML Policy",
	"el": "Πολιτική AML",
	"es": "Política de AML",
	"de": "AML Bestimmungen"
}

export const PrivacyLabel = {
	"en": "Privacy Policy",
	"el": "Πολιτική Απορρήτου",
	"de": "Datenschutzpolitik",
	"es": "Política de Privacidad",
	"se": "Sekretesspolicy",
	"tr": "Gizlilik Politikası"
}

export const ProfitTaxLabel = {
	"el": "Φορολογία Κερδών"
}

export const AffiliateLabel = {
	"en": "Affiliates",
	"el": "Συνεργάτες",
	"de": "Partner",
	"es": "Afiliados",
	"se": "Affiliateprogram",
	"tr": "Ortaklar"
}

export const ContactUsLabel = {
	"en": "Contact Us",
	"el": "Επικοινωνία",
	"de": "Kontaktaufnahme",
	"es": "Contáctanos",
	"se": "Kontakta oss",
	"tr": "Bize Ulaşın"
}

export const FAQLabel = { 
	"en": "FAQ",
	"de": "FAQ",
	"tr": "S.S.S",
	"es": "FAQ",
	"el": "Συχνές Ερωτήσεις"
}

export const TimeLabel = { 
	"en": "Time",
	"el": "Ώρα",
	"de": "Zeit",
	"es": "Hora",
	"se": "Tid",
	"tr": "Zaman"
}

export const Min = { 
	"en": "min",
	"el": "min",
	"de": "min",
	"es": "min",
	"se": "min",
	"tr": "dk"
}

export const Year = { 
	"en": "Year",
	"el": "Έτος",
	"de": "Jahr",
	"tr": "Yıl",
	"es": "Año"
}

export const Month = { 
	"en": "Month",
	"el": "Μήνας",
	"de": "Monat",
	"tr": "Ay",
	"es": "Mes"
}

export const ServerConnError = { 
	"en": "Server connection error! Please try again later.",
	"el": "Σφάλμα σύνδεσης server. Προσπάθησε αργότερα.",
	"de": "Server-Verbindungsfehler! Bitte versuche es später noch einmal.",
	"es": "Error de conexión del servidor! Por favor, inténtalo de nuevo más tarde."
}

export const PromoCode = { 
	"en": "Promotional code",
	"el": "Προωθητικός κωδικός",
	"de": "Promotionscode",
	"es": "Codigo promocional",
	"se": "Kampanjkod",
	"tr": "Promosyon kodu"
}

export const THUMBNAIL_FREE_GAMES = { 
	"en": "{amount} FREE GAMES!",
	"el": "{amount} ΔΩΡΕΑΝ ΠΑΙΧΝΙΔΙΑ!",
	"de": "{amount} KOSTENLOSE SPIELE!",
	"es": "{amount} JUEGOS GRATIS!",
	"se": "{amount} GRATISSPEL!",
	"tr": "{amount} ÜCRETSİZ OYUN!"
}

export const CopiedLabel = { 
	"en": "Copied!",
	"de": "Kopiert!",
	"es": "Copiado",
	"se": "Kopierat!",
	"tr": "Kopyalandı!",
	"el": "Αντιγράφηκε!"
}

export const sendBlueberryStartP2PSession_Title = { 
	"en": "Error reading card",
	"el": "Σφάλμα ανάγνωσης κάρτας",
	"de": "Fehler beim Lesen der Karte",
	"es": "Error en la lectura de tarjeta",
	"se": "Fel vid kortläsning",
	"tr": "Kart okuma hatası"
}

export const sendBlueberryStartP2PSession_Text = { 
	"en": "Please check your language settings.",
	"el": "Έλεγξε τις ρυθμίσεις γλώσσας.",
	"de": "Überprüfe bitte deine Spracheinstellungen.",
	"es": "Por favor, comprueba tu configuración de idioma.",
	"se": "Vänligen kontrollera dina språkinställningar.",
	"tr": "Lütfen dil ayarlarınızı kontrol edin."
}

export const blueberrySMS_Title = { 
	"en": "Card authorization",
	"el": "Έγκριση κάρτας",
	"de": "Kartenautorisierung",
	"es": "Autorización de tarjeta",
	"se": "Auktorisering av kort",
	"tr": "Kart yetkilendirme"
}

export const confirm_label = { 
	"en": "Confirm",
	"el": "Επιβεβαίωση",
	"de": "Bestätigen",
	"es": "Confirmar",
	"se": "Bekräfta",
	"tr": "Onayla"
}

export const FSLeftToPlayTitle = { 
	"en": "You have free spins left to play!",
	"el": "Έχεις free spins που δεν έχεις παίξει!",
	"de": "Es bleiben dir noch Freispiele!",
	"es": "Tienes tiradas gratis para jugar!"
}

export const FSLeftToPlayText = { 
	"en": "Fnalise your free spins by selecting Bombagames game “{0}”.",
	"el": "Παίξε τα υπόλοιπα free spins σου επιλέγοντας το παιχνίδι «{0}».",
	"de": "Spiele die übrigen Freispiele beim Bombagames Spiel “{0}”.",
	"es": "Finaliza tus tiradas gratis seleccionando la tragamonedas Bombagames \"{0}\"."
}

export const resumedNoDevice_text = { 
	"en": "The game {0} can only be resumed on desktop and not on a mobile device!",
	"el": "Το παιχνίδι {0} μπορεί να συνεχιστεί μόνο σε σταθερό υπολογιστή και όχι σε φορητή συσκευή!",
	"de": "Das Spiel {0} kann nur auf dem Desktop und nicht auf einem Mobilgerät fortgesetzt werden!",
	"es": "¡El juego {0} solo se puede reanudar en el ordenador de escritorio y no en un dispositivo móvil!"
}

export const CloseCurrGameTextOneGame = { 
	"en": "You're currently playing {0}. Please close the game to start another game.",
	"el": "Αυτή τη στιγμή παίζεις {0}. Θα πρέπει να κλείσεις το ανοικτό παιχνίδι για να ξεκινήσεις ένα άλλο παιχνίδι.",
	"de": "Du spielst gerade {0}. Bitte schließe das Spiel, um ein anderes Spiel zu starten.",
	"es": "Actualmente estás jugando {0}. Cierre el juego para iniciar otro juego."
}



export const UpdateTermsTitle = { 
	"en": "Info",
	"el": "Ενημέρωση",
	"de": "Infos",
	"es": "info",
	"se": "Information",
	"tr": "Info"
}

export const WebGLError = { 
	"en": "WebGL is not enabled on this device so you might experience problems loading our games. <br/>Please enable it or contact our customer support team.",
	"el": "Η διεπαφή λογισμικού WebGL δεν είναι ενεργοποιημένη στη συσκευή σου και δεν επιτρέπει την ολοκλήρωση φόρτωσης των παιχνιδιών. Ενεργοποίησέ την ή επικοινώνησε με την Εξυπηρέτηση Πελατών.",
	"de": "WebGL ist auf diesem Gerät nicht aktiviert, daher können beim Laden unserer Spiele Probleme auftreten. Bitte aktiviere es oder wende dich an unseren Kundendienst.",
	"tr": "WebGL bu cihazda etkin değil, bu yüzden oyunlarımızı yüklerken sorun yaşayabilirsiniz.  Lütfen etkinleştirin veya müşteri destek ekibimizle iletişim kurun.",
	"es": "WebGL no está habilitado en este dispositivo, por lo que podrías experimentar problemas al cargar nuestros juegos. Por favor habilítalo o contacta con nuestro equipo de atención al cliente"
}

export const Other = { 
	"en": "Other",
	"el": "Άλλο",
	"de": "Andere",
	"es": "Otro",
	"se": "Annat",
	"tr": "Diğer"
}

export const PassResetCodeLabel = { 
	"en": "Code",
	"el": "Κωδικός Ανάκτησης",
	"de": "Code",
	"es": "Código",
	"se": "Kod",
	"tr": "Kod"
}




