import model from "@js/model.js";
import controller from "@js/controller/controller.js";
import { watch } from "vue"
import Enum from "@shared/enum.js";
import cookieHelper from "@helpers/cookie-helper.js";
const ZENDESK_SRCIPT_STR = "<script id=\"main-zendesk-script\">\/\*<![CDATA[*/window.zEmbed||function(e,t){var n,o,d,i,s,a=[],r=document.createElement(\"iframe\");window.zEmbed=function(){a.push(arguments)},window.zE=window.zE||window.zEmbed,r.src=\"javascript:false\",r.title=\"\",r.role=\"presentation\",(r.frameElement||r).style.cssText=\"display: none\",d=document.getElementsByTagName(\"script\"),d=d[d.length-1],d.parentNode.insertBefore(r,d),i=r.contentWindow,s=i.document;try{o=s}catch(e){n=document.domain,r.src='javascript:var d=document.open();d.domain=\"'+n+'\";void(0);',o=s}o.open()._l=function(){var e=this.createElement(\"script\");n&&(this.domain=n),e.id=\"js-iframe-async\",e.src=\"https://assets.zendesk.com/embeddable_framework/main.js\",this.t=+new Date,this.zendeskHost=\"4starssupport.zendesk.com\",this.zEQueue=a,this.body.appendChild(e)},o.write('<body onload=\"document._l();\">'),o.close()}();/*]]>*/</script>"

export default class ZendeskHelper {
	constructor() {
		model.openChat = () => {
			typeof zE === "function" && zE('webWidget', 'open');
			if (model.isMobile)
				controller.closeAllModals();
		};

		if (!model.zendesk || !model.zendesk.active || model.isNotView(Enum.Views.MAIN))
			return;

		window.zESettings = {
			webWidget: {
				position: { horizontal: 'right', vertical: 'bottom' },
				offset: {
					mobile: {},
				},
			},
		};

		if (model.UIID === Enum.UIIDs.FOUR_SG || model.UIID === Enum.UIIDs.SKY) {
			window.zESettings.webWidget.chat = {
				title: {
					'*': 'Live Chat'
				},
				concierge: {
					name: model.companyName + ' Assistant',
				},
			};
			if(model.isApp) window.zESettings.webWidget.offset.mobile.vertical = '100px';
		}
		else if (model.isUI('FUND')) {
			window.zESettings.webWidget.chat = {
				title: {
					'*': '24/7 Live Chat',
					es: '24/7 Chat en Vivo',
				},
				concierge: {
					title: {
						'*': 'Ask us anything!',
						es: '¡Pregúntanos lo que quieras!',
					},
				},
			};
			window.zESettings.webWidget.offset.mobile.vertical = '100px';
		}

		if(model.isApp){
			window.initZendesk = () => {
				init.call(this);
				delete window.initZendesk;
			}
		}
		else{
			if(model.hasCookieLaw) {
				if (cookieHelper.get('cookie_law_informed')) init.call(this);
				else {
					model.on('CookiesConfirmed', (e) => {
						init.call(this);
						e.dispose();
					});
				}
			}
			else init.call(this);
		}

		var organization = null;

		function init() {
			$(document.body).append(model.redeemLangObj(model.zendesk.scriptElem) || ZENDESK_SRCIPT_STR);

			var titleElements = window.document.head.getElementsByTagName('title');
			organization = titleElements.length > 0 ? titleElements[0].text : '';

			model.on('LoginComplete', () => {
				if (!window.zE || !window.zE.identify) return;
				identifyAsAuth.call(this);
			});

			let int = setInterval(() => {
				if(!window.zE) return;

				clearInterval(int);

				zE(() => {
					zE.setLocale(model.lang);
					if (model.logged && model.userProfile) identifyAsAuth.call(this);
	
					$zopim(() => {
						setLang.call(this);
	
						$zopim.livechat.badge.hide();

						if(model.isMobile && model.isUI('FUND')){
							$zopim.livechat.window.onHide(() => {
								zE('webWidget', 'hide');
							});
						}
	
						if (model.zendesk.theme) {
							if (model.zendesk.theme.color)
								$zopim.livechat.theme.setColor(model.zendesk.theme.color);
							if (model.zendesk.theme.profileCardConfig)
								$zopim.livechat.theme.setProfileCardConfig(model.zendesk.theme.profileCardConfig);
						}
	
						if (model.zendesk.concierge && model.zendesk.concierge.avatar)
							$zopim.livechat.concierge.setAvatar(model.zendesk.concierge.avatar);
	
						$zopim.livechat.theme.reload();
	
						triggerWidgetDisplay.call(this);
	
						if (model.isUI('FUND') && model.isMobile) {
							model.vue.root.$watch('modalDisplayed', () => triggerWidgetDisplay.call(this));
							watch(() => model.observables.notifOpened, () => triggerWidgetDisplay.call(this));
						}
						else{
							watch(() => model.vue.root.mobileMode, () => triggerWidgetDisplay.call(this));
						}
	
						model.on('GameStarted', 'GameEnded', 'GameDisplayed', 'OpenPage', () => triggerWidgetDisplay.call(this));
						
						model.dispatchEvent('ZendeskLoaded');
					});
				});
			}, 100);

			model.on('SetLang', () => setLang.call(this));

			function triggerWidgetDisplay() {
				if (model.isUI('FUND')) {
					if(model.isMobile) zE('webWidget', 'hide');
					else{
						zE('webWidget', (
							controller.anyModals() ||
							model.observables.notifOpened ||
							controller.isAGameDisplayed() || 
							model.vue.root.mobileMode
						) ? 'hide' : 'show');
					}
					
					return;
				}

				zE('webWidget', (controller.isAGameDisplayed() || model.isPage(Enum.Pages.SPORTBETTING)) ? 'hide' : 'show');
			}
		}

		function identifyAsAuth() {
			model.userProfile && zE.identify && zE.identify({
				name: model.userProfile.fullName,
				email: model.userProfile.email,
				organization: organization
			});
		}

		function setLang() {
			var newLang = model.lang;
			window?.zE?.setLocale?.(newLang);
			window.$zopim?.livechat.setLanguage(newLang);

			if (!model.isUI('FUND')) return;

			window.$zopim?.livechat?.concierge.setName(model.redeemLangObj({
				en: 'Customer Support',
				es: 'Atención al Cliente',
			}));
		}
	}
}