import model from "@js/model.js";
import controller from "@js/controller/controller.js";
import { nextTick } from "vue";
export default {
	methods: {
		_initModalHandler(){
			$(document).on('shownbsmodal', (e) => {
				let modal = model.observables.modals.find(x => x.uiContext == $(e.target).data('ui-context'));
				if(!modal) return;

				modal.visible = true;

				model.dispatchEvent('CloseNavMenu', 'HideMobileNav');

				model.isMobile && (this.modalDisplayed = true);

				!model.isMobile && model.dispatchEvent('LockBodyScroll');
				model.dispatchEvent('HideAllMsgs');

				if(model.isMobile) {
					nextTick(() => $(document).scrollTop(0));
				}
			});

			$(document).on('hiddenbsmodal', e => {
				let modal = model.observables.modals.find(x => x.uiContext == $(e.target).data('ui-context'));
				if(!modal) return;

				modal.shown = false;

				if (!modal.preserved) {
					controller.removeModal(modal);
					controller.loadPrevModal();
				}

				modal.preserved = false;
				var anyModals = controller.anyModals();

				if (model.isMobile && !anyModals) {
					this.modalDisplayed = false;
				}

				model.dispatchEvent('UnlockBodyScroll', 'WindowResize');
				model.dispatchEvent('ModalClosed', {modal});
				!anyModals && model.dispatchEvent('DisplayAllMsgs');
			});
		}
	},
};