import { nextTick } from "vue"
import model from "../model.js";
import iscrollCommonProps from "./iscroll-common-props.js";
import { Linear, gsap } from "gsap";
import { isObject } from "@helpers/utils.js";
import { SwipeLeftRightLabel } from "@lang/gameFrameMobile.js";

const iscrollCachedPositions = {};

export default function(){
	let unnamedIScrollsNum = 0;
	
	model.vue.app.directive('setup-iscroll', {
		unmounted(element){
			element.onDispose?.();
		},
		beforeMount(element, binding){
			var props = binding.value;
			if(!isObject(props)) return;

			model.pushLang({SwipeLeftRightLabel});
	
			$.extend(props.params, iscrollCommonProps);
	
			if(typeof props.shouldCreate !== 'boolean') props.shouldCreate = true;
			if(typeof props.expectedAsList !== 'boolean') props.expectedAsList = false;
	
			if(!props.shouldCreate) return;
	
			var parent = props.parent || binding.instance;
	
			if(!props.variableName) props.variableName = 'unnamed_iscroll_' + unnamedIScrollsNum++;
			else props.hasDefinedVarName = true;
	
			if(props.expectedAsList){
				props.variableName += "List";
				nextTick(() => setupIScrollInstance(props, element, parent));
			}
			else{
				if(props.initManually) parent['init_' + props.variableName] = () => setupIScrollInstance(props, element, parent);
				else nextTick(() => setupIScrollInstance(props, element, parent));
			}
	
			element.onDispose = () => {
				if(props.expectedAsList){
					var arr = parent[props.variableName];
					var targetItem = arr.find(item => item.element === element);
					if(targetItem){
						disposeInstance(targetItem.instance);
						arr.splice(arr.indexOf(targetItem), 1);
						
						let swipeLangCallback = targetItem?.instance?.swipeLangCallback;
						if(swipeLangCallback) model.off('SetLang', swipeLangCallback);
					}
				}
				else{
					let swipeLangCallback = parent[props.variableName]?.swipeLangCallback;
					if(swipeLangCallback) model.off('SetLang', swipeLangCallback);
					
					delete parent['init_' + props.variableName];
					delete parent[props.variableName + '_initialized'];
					disposeInstance(parent[props.variableName]);
					delete parent[props.variableName];
				}
			}
	
			function disposeInstance(instance){
				if(!instance) return;
	
				typeof instance.destroy === 'function' && instance.destroy();
				instance.$modal && instance.$modal.off('shownbsmodal', instance.onWindowResize);
	
				if(instance.$arrowPrev) instance.$arrowPrev.remove();
				if(instance.$arrowNext) instance.$arrowNext.remove();
				if(instance.$swipeNotifyCont) instance.$swipeNotifyCont.remove();
				if(instance.$dotsContainer) instance.$dotsContainer.remove();
	
				model.off('WindowResize', 'ForceResize', instance.onWindowResize);
				model.off('WindowResize', 'ForceResize', instance.onUpdateAutoScroll);
	
				delete instance.onWindowResize;
				delete instance.onUpdateAutoScroll;
	
				instance.asTween && instance.asTween.kill();
			}
		},
		updated(element, binding){
			var props = binding.value;
			if(!isObject(props)) return;
	
			var parent = props.parent || binding.instance;
	
			if(props.expectedAsList){
				props.variableName += 'List';
				var targetItem = parent[props.variableName].find(item => item.element === element);
				targetItem && onUpdate(targetItem.instance);
			}
			else onUpdate(parent[props.variableName]);
	
			function onUpdate(instance){
				if(!instance) return;
				instance.predefinedWidth = props.predefinedWidth;
				typeof instance.onWindowResize === 'function' && instance.onWindowResize();
			}
		},
	});
	
	function setupIScrollInstance(props, element, parent){
		let instance = new IScroll(props.params.wrapper || element, props.params);
	
		if(props.expectedAsList){
			parent[props.variableName] = parent[props.variableName] || [];
			parent[props.variableName].push({
				instance: instance,
				element: element,
			});
		}
		else{
			parent[props.variableName] = instance;
			parent[props.variableName + '_initialized'] = true;
		}

		if(props.hasDefinedVarName){
			if(!iscrollCachedPositions[props.variableName]) iscrollCachedPositions[props.variableName] = {x: 0, y: 0, pageX: 0};
			let cachedObj = iscrollCachedPositions[props.variableName];

			instance.on('scroll', () => {
				cachedObj.x = instance.x;
				cachedObj.y = instance.y;
				cachedObj.pageX = instance.currentPage?.pageX;
			});

			setTimeout(() => {
				instance.scrollTo(cachedObj.x, cachedObj.y);
				if(instance.currentPage) instance.currentPage.pageX = cachedObj.pageX;
			}, 0);
		}

		props.disabled && instance.disable();
		var maxWidthOnParent = props.maxWidthOnParent;
	
		model.dispatchEvent('IScrollAdded', {
			variableName: props.variableName,
			instance: instance,
		});
	
		instance.arrows = props.arrows;
	
		if(typeof props.onInit === 'function') props.onInit();
	
		var childSelector = props.childSelector;
		childSelector = childSelector || '';
	
		var $element = $(element);
		instance.childSelector = ':not(.hidden)' + childSelector;
		instance.$element = $element;
		instance.$parent = $element.parent();
		instance.$scroller = $element.children().first();
		
		instance.$parent.addClass('list-parent');

		instance.alwaysRefresh = props.alwaysRefresh;
		if(typeof instance.alwaysRefresh !== 'boolean')
			instance.alwaysRefresh = false;
	
		instance.$modal = $element.parents('.modal');
		var isPartOfModal = instance.$modal.length > 0;
	
		if(typeof props.displaySwipeMsg !== 'boolean') props.displaySwipeMsg = true;
	
		instance.predefinedWidth = props.predefinedWidth;
		instance.dots = Boolean(props.dots);
		instance.retainOverflow = Boolean(props.retainOverflow);
	
		instance.onScrollEnd = props.onScrollEnd;
	
		var itemsStretched = Boolean(props.itemsStretched);
	
		if(props.dots){
			instance.$dotsContainer = $('<div class="dots"></div>');
			instance.$dotsContainer.insertAfter(instance.$element);
			updateDots.call(instance);
			instance.on('scrollEnd', function(){
				updateActiveDot.call(this);
			});
			instance.$dotsContainer.on('click', '.dot', function(e){
				setupTweenAction($(this).index() * instance.wrapperWidth);
			});
		}

		if(props.displayMobileArrows && model.isMobile){
			let $parent = $('<div class="mobile-arrows">');
			$parent.append($('<div class="left">'));
			$parent.append($('<div class="right">'));
			instance.$parent.append($parent);
			instance.$mobileArrows = $parent;
			instance.on('scroll', () => {
				handleMobileArrowsDisplay.call(instance);
			});
		}
	
		updateRetainedOverflow.call(instance, 'idle');
	
		itemsStretched && instance.$element.addClass('items-stretched');
	
		instance.on('refresh', function(){
			if(!shouldRefresh()) return;
	
			var predefWidthVal = this.predefinedWidth;
	
			if(typeof predefWidthVal === 'function'){
				predefWidthVal = predefWidthVal.call(this);
			}
	
			var width = predefWidthVal || calcScrollerWidth.call(this);
	
			var widthInt = width;
			if(typeof widthInt === 'string' && widthInt.includes('vw'))
				widthInt = window.innerWidth * Number(widthInt.replace(/\D/g, '')) / 100;
	
			var scrollLessWrapper = widthInt <= this.wrapperWidth;
			this.$scroller.width(width);
			this.actualScrollerWidth = width;
	
			if(maxWidthOnParent){
				this.$element.css('max-width', width);
			}
	
			props.forceToRight && this.$scroller.css('right', scrollLessWrapper ? 0 : '');
	
			var ww = model.isMobile ? window.innerWidth : $element.outerWidth(false);
			var $activeElem = this.$scroller.children('.active');
	
			if($activeElem.length > 0) this.scrollToElement($activeElem.get(0), 0, (ww / 2 - $activeElem.outerWidth(true) / 2) * -1);
	
			this.$swipeNotifyCont && this.$swipeNotifyCont.css('display', (scrollLessWrapper || !width) ? 'none' : '');
	
			handleArrowsDisplay.call(this);
			handleMobileArrowsDisplay.call(this);
			updateDots.call(this);
			updateRetainedOverflow.call(instance, 'idle');
		});
		
		instance.onWindowResize = function(e){
			if(!shouldRefresh()) return;
	
			nextTick(() => {
				if(!instance.arrowsDisplayed) nextTick(() => instance.refresh());
				instance.refresh();
			});
		};
	
		model.isMobile && (function(){
			if(!props.autoscroll) return;
	
			instance.asTween = null;
	
			var timeout = null, opposite = false;
	
			var speed = 10;
	
			var obj = {
				x: 0,
			};
	
			instance.onUpdateAutoScroll = onUpdateAutoScroll;
	
			onUpdateAutoScroll();
			model.on('WindowResize', 'ForceResize', onUpdateAutoScroll);
			
			instance.on('refresh', function(){
				killTween();
				onUpdateAutoScroll();
			});
	
			instance.on('scrollStart', function(){
				killTween();
			});
	
			instance.on('scrollEnd', function(){
				killTween();
				
				obj.x = this.x;
	
				onUpdateAutoScroll();
			});
	
			function onUpdateAutoScroll(){
				killTween();
				timeout = setTimeout(function(){
					var ww = window.innerWidth;
					var dist = instance.scrollerWidth - ww;
					var extraDist = ww - instance.$element.outerWidth(false);
					dist += extraDist;
	
					if(instance.scrollerWidth < ww) return;
					
					var newDistance = !opposite ? (dist * -1) : 0;
					if(Math.abs(newDistance - obj.x) <= 50){
						moveHiddenItemsToRight();
						onUpdateAutoScroll();
						return;
					}
	
					var n = instance.scrollerWidth / ww;
					instance.asTween = gsap.to(obj, {
						duration: speed * n,
						x: newDistance,
						ease:Linear.easeNone,
						onUpdate(){
							instance.scrollTo && instance.scrollTo(obj.x, 0);
						},
						onComplete(){
							moveHiddenItemsToRight();
							onUpdateAutoScroll();
						},
					});
	
					function moveHiddenItemsToRight(){
						let $children = instance.$scroller.children(instance.childSelector);
							
						let hiddenChildren = [];
						$children = $children.each((__, child) => {
							let $child = $(child);
							let scrollerLeft = instance.$scroller.position().left;
							if($child.position().left + scrollerLeft + $child.outerWidth(true) < instance.$element.position().left) hiddenChildren.push($child)
						});
	
						let amountToMove = 0;
	
						hiddenChildren.forEach($child => {
							$child.parent().append($child);
							amountToMove += $child.outerWidth(true);
						});
	
						obj.x += amountToMove;
						instance.scrollTo(obj.x, 0);
					}
				}, 100);
			}
	
			function killTween(){
				instance.asTween && instance.asTween.kill();
				instance.asTween = null;
				clearTimeout(timeout);
			}
		}());
	
		model.on('WindowResize', 'ForceResize', instance.onWindowResize);
		instance.onWindowResize();
		isPartOfModal && instance.$modal.on('shownbsmodal', instance.onWindowResize);
	
		if(!props.dots && model.isMobile && props.displaySwipeMsg && !window.sessionStorage.getItem('PrevCategory')){
			instance.$swipeNotifyCont = $('<div class="swipe-notify-cont"></div>');
			
			var $msg = $('<div class="msg"></div>');
			$msg.append(
				`
					<div class="arrow-left">
						<div class="inner"></div>
					</div>
				`
			);
			$msg.append('<div class="text"></div>');
			$msg.append(
				`
					<div class="arrow-right">
						<div class="inner"></div>
					</div>
				`
			);
			
			instance.$swipeNotifyCont.append($msg);
			instance.$swipeNotifyCont.appendTo(instance.$parent);
			instance.on('scrollStart', onSwipeOnce);
	
			instance.swipeLangCallback = model.on('SetLang', () => {
				instance.$swipeNotifyCont.find('.text').html(model.getStr('SwipeLeftRightLabel'));
			});
			instance.swipeLangCallback();
	
			instance.$scroller.on('click', instance.childSelector, (function(){
				function callback(e){
					instance.$swipeNotifyCont.addClass('swiped-once');
					instance.$scroller.off('click', callback);
				}
				return callback;
			}()));
			return;
		}
	
		instance.$scroller.on('click', childSelector, function(e){
			!$(this).hasClass('disabled') && setTimeout(function(){
				instance.refresh();
			}, 0);
		});
	
		if(!props.arrows && !props.dots)
			return;
	
		instance.$arrowPrev = $(`<div class="arrow-prev ${props.arrowsInner ? 'inner' : ''}"><div class="inner"></div></div>`);
		instance.$arrowNext = $(`<div class="arrow-next ${props.arrowsInner ? 'inner' : ''}"><div class="inner"></div></div>`);
	
		if(props.arrows) (props.arrowsInner ? instance.$element : instance.$parent).append(instance.$arrowPrev, instance.$arrowNext);
		
		var tween = null;
	
		var threshold = props.threshold || 0;
		var prevDist = 0;
		
		if(!props.dots){
			instance.$arrowNext.on('click', function(e){
				var targetDistance = 0;
				var itemWidth = 0;
				var isLastElem = false;
		
				var $sel = instance.$scroller.children(childSelector);
				var itemLength = $sel.length;
		
				$sel.each(function(index){
					var $item = $(this);
		
					var distance = $item.position().left + $item.outerWidth(true);
					
					if(distance > Math.abs(instance.x) + instance.wrapperWidth){
						if(tween){
							var $next = $item.next();
							if($next.length){
								$item = $next;
								distance = $item.position().left + $item.outerWidth(true);
								index++;
							}
						}
		
						!$item.next(instance.childSelector).length && instance.$arrowNext.addClass('disabled');
						targetDistance = Math.ceil(distance - instance.wrapperWidth);
						itemWidth = $item.outerWidth(true);
						isLastElem = index === itemLength - 1;
						return false;
					}
				});
		
				if(Math.abs(targetDistance - prevDist) <= threshold && !isLastElem){
					targetDistance = prevDist + Math.abs(targetDistance - prevDist);
					targetDistance += itemWidth;
				}
		
				setupTweenAction(targetDistance);
				prevDist = targetDistance;
			});
		
			instance.$arrowPrev.on('click', function(e){
				var targetDistance = 0;
				var __itemWidth = 0;
				var isLastElem = false;
		
				var $sel = instance.$scroller.children(childSelector);
		
				$sel.each(function(index){
					var $item = $(this);
					var itemWidth = $item.outerWidth(true);
					var distance = $item.position().left + itemWidth;
					var targetX = Math.abs(instance.x);
					if(Math.ceil(distance) >= Math.floor(targetX)){
						if(tween){
							var $prev = $item.prev(instance.childSelector);
							if($prev.length){
								$item = $prev;
								itemWidth = $item.outerWidth(true);
								distance = $item.position().left + itemWidth;
								index--;
							}
						}
		
						!$item.prev(instance.childSelector).length && instance.$arrowPrev.addClass('disabled');
						targetDistance = Math.floor(distance - itemWidth);
						__itemWidth = $item.outerWidth(true);
						isLastElem = index === 0;
						return false;
					}
				});
		
				if(Math.abs(targetDistance - prevDist) <= threshold && !isLastElem){
					targetDistance = prevDist - Math.abs(targetDistance - prevDist);
					targetDistance -= __itemWidth;
				}
		
				setupTweenAction(targetDistance);
				prevDist = targetDistance;
			});
		}
		else{
			instance.$arrowNext.on('click', () => {
				setupTweenAction((getPageNumber.call(instance) + 1) * instance.wrapperWidth);
			});

			instance.$arrowPrev.on('click', () => {
				setupTweenAction((getPageNumber.call(instance) - 1) * instance.wrapperWidth);
			});
		}
	
		function setupTweenAction(targetDistance){
			var obj = {
				x: instance.x,
			};
	
			if(tween){
				tween.kill()
				tween = null;
			}
	
			updateRetainedOverflow.call(instance, 'moving');
	
			tween = gsap.to(obj, {
				duration: .25,
				x: targetDistance * -1,
				onUpdate: function(){
					instance.scrollTo(obj.x, 0);
				},
				onComplete: function(){
					tween = null;
					handleArrowsDisplay.call(instance);
					updateActiveDot.call(instance);
					updateRetainedOverflow.call(instance, 'idle');
				},
			});
		}
	
		/**
		 * IScroll instances should not be refreshed if they're completely hidden. In this case, if the 
		 * instace is a part of a modal window, it should be refreshed only if its modal window is displayed. 
		 * However, if it's not a part of a modal window, it should be refreshed only if all modal windows are closed. 
		 * This function currently only has an affect on mobile devices for now. 
		 * @return {Boolean} True to refresh the iscroll instance, false otherwise. 
		 */
		function shouldRefresh(){
			if(instance.alwaysRefresh || !model.isMobile) return true;
			return instance.$element.is(':visible');
			//return isPartOfModal ? controller.anyModals() : !controller.anyModals();
		}
	}
	
	function updateDots(){
		if(!this.dots) return;
	
		var $children = this.$scroller.children(this.childSelector);
	
		this.$dotsContainer.empty();
		for(var i = 0, len = $children.length; i < len; i++){
			this.$dotsContainer.append('<div class="dot"></div>');
		}
	
		updateActiveDot.call(this);
	}
	
	function updateActiveDot(){
		if(!this.dots) return;
		
		var pageX = getPageNumber.call(this);
	
		this.$dotsContainer.children().each(function(index){
			$(this)[index == pageX ? 'addClass' : 'removeClass']('active');
		});
	
		if(this.prevPageX !== pageX && typeof this.onScrollEnd === 'function'){
			this.onScrollEnd(pageX);
		}
	
		this.prevPageX = pageX;
	}
	
	function updateRetainedOverflow(state){
		if(model.isMobile || !this.retainOverflow) return;
	
		switch(state){
			case 'idle' : 
				this.$element.css('overflow', 'visible');
				var pageX = getPageNumber.call(this);
				this.$scroller.children(this.childSelector).each(function(index){
					var $item = $(this);
					if(pageX === index){
						$item.css('opacity', '');
						$item.removeClass('no-pointer-events');
						$item.addClass('active');
					}
					else{
						$item.css('opacity', '0.0001');
						$item.addClass('no-pointer-events');
						$item.removeClass('active');
					}
				});
				break;
			case 'moving' : 
				this.$element.css('overflow', 'hidden');
				this.$scroller.children(this.childSelector).each(function(index){
					$(this).css('opacity', '').addClass('no-pointer-events');
				});
				break;
		}
	}
	
	function getPageNumber(){
		var num = Math.abs(this.x / this.wrapperWidth);
		var tmpNum = Math.floor(num) + .5;
	
		if(num > tmpNum) return Math.ceil(num);
		return Math.floor(num);
	}
	
	function handleArrowsDisplay(){
		if(model.isMobile) return;
	
		if(!this.arrows) return;
		
		var ww = this.wrapperWidth;
		
		if(this.arrowsDisplayed){
			ww += 50;
		}
	
		if(ww >= this.scrollerWidth){
			this.$parent.removeClass('arrows-displayed');
			this.arrowsDisplayed = false;
		}
		else
		{
			this.$parent.addClass('arrows-displayed');
			this.arrowsDisplayed = true;
			this.$arrowPrev[this.x == 0 ? 'addClass' : 'removeClass']('disabled');
			this.$arrowNext[Math.floor(this.x) <= (this.scrollerWidth - this.wrapperWidth) * -1 ? 'addClass' : 'removeClass']('disabled');
		}
	}

	function handleMobileArrowsDisplay(){
		if(!model.isMobile || !this.$mobileArrows) return;

		let $left = this.$mobileArrows.find('.left');
		let $right = this.$mobileArrows.find('.right');

		$left[this.x > -10 ? 'addClass' : 'removeClass']('hidden');
		$right[Math.floor(this.x) <= (this.scrollerWidth - this.wrapperWidth) * -1 ? 'addClass' : 'removeClass']('hidden');
	}
	
	function onSwipeOnce(){
		if(!model.isMobile)
			return;
	
		this.$swipeNotifyCont && this.$swipeNotifyCont.addClass('swiped-once');
		this.off('scrollStart', onSwipeOnce);
	}
	
	/**
	 * Loops through all of the scroller children and calculates a sum of all of their widths. 
	 * Unfortunately, the scrollWidth property of iScroll does not work that way. 
	 * @return {Number} Sum width of all of the scroller's children. 
	 */
	function calcScrollerWidth(){
		var width = 0;
	
		//IE fix
		this.$scroller.children(this.childSelector).each(function(){
			width += $(this).outerWidth(true);
		});
	
		return width;
	}
}