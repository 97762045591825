export const FillAllProfileInfo = {
	en: 'Please fill all profile info before proceeding.',
	es: 'Complete toda la información del perfil antes de continuar.',
};

export const InsufficientBalance = {
	en: 'Your balance is too low.',
	es: 'Su saldo es demasiado bajo.',
};

export const NewAccount = {
	en: 'New Account',
	es: 'Nueva Cuenta',
};

export const NoPaymentMethodsText = {
	en: 'No available payment methods',
	es: 'No métodos de pago encontrados',
};

export const PleaseContactSupport = {
	en: 'Please contact support',
	el: 'Επικοινωνήστε με την υποστήριξη.',
	es: 'Por favor contacte con soporte',
};

export const BIC = {
	"en": "BIC",
};

export const BankNameLabel = {
	"en": "Bank Name",
	"es": "Nombre del banco",
};

export const AccountTypeLabel = {
	"en": "Account Type",
	"es": "Tipo de cuenta",
};

export const IBAN = {
	"en": "IBAN",
};

export const BeneficiaryName = {
	"en": "Beneficiary name",
	"es": "Nombre del beneficiario",
};

export const WalletAddress = {
	"en": "Wallet Address",
};

export const CardHolderNameLabel = {
	"en": "Cardholder Name",
	"el": "όνομα ιδιοκτήτη κάρτας",
	"de": "Name des Karteninhabers",
	"es": "Nombre del titular de la tarjeta",
};

export const AccDeletedFailureText = { 
	"en": "Failed to delete account, contact support.",
	"de": "Konto konnte nicht gelöscht werden, bitte kontaktieren Sie den Support."
}

export const AccDeletedSuccessText = { 
	"en": "Account successfully deleted.",
	"es": "Cuenta eliminada exitosamente."
}

export const ConfirmAccDeletion = { 
	"en": "Confirm account deletion?",
	"es": "¿Confirmar la eliminación de la cuenta?",
}

export const DeleteAccLabel = { 
	"en": "Delete account",
	"de": "Konto löschen",
	"es": "Elimina cuenta de pago"
}

export const SelectAccLabel = { 
	"en": "Select account",
	"de": "Konto auswählen",
	"es": "Seleccionar cuenta"
}

export const PaymentPendingPageLowerText = { 
	"en": "No luck? Try seeing if popups are disabled on your browser. ",
	"de": "Kein Glück? Bitte prüfen Sie, ob Popups in Ihrem Browser deaktiviert sind.",
	"es": "¿Sin suerte? Intente ver si las ventanas emergentes están deshabilitadas en su navegador.",
}

export const PaymentPendingPageUpperLabel = { 
	"en": "Waiting for {0}.",
	"de": "Warten auf {0}.",
	"es": "Esperando por {0}."
}

export const NewPaymentLabel = {  
	"en": "New payment",
	"de": "Neue Zahlung",
	"es": "Nuevo Pago",
}

export const SecurityCodeLabel = { 
	"en": "Security Code",
	"de": "Sicherheitscode",
	"es": "Código de seguridad"
}

// export const NetellerAccId = {
// 	"en": "Neteller account ID"
// }

export const SelPaymentMethodText = { 
	"en": "Select a payment method",
	"es": "Selecciona tu metodo de pago"
}

export const ImportantNotice = {  
	"en": "Important Notice",
	"el": "Σημαντική Ενημέρωση"
}

export const X1LoginText = {
	"en": "In order to make deposits with credit cards, please use the button \"{deposit-cc}\" at the top and follow the instructions. Should you have any questions, contact Support through Live Chat or email.",
	"el": "Για καταθέσεις με πιστωτική/χρεωστική κάρτα, παρακαλούμε πατήστε το κουμπί \"{deposit-cc}\" και ακολουθήστε τις οδηγίες. Για οποιαδήποτε απορία, επικοινωνήστε με το Τμήμα Εξυπηρέτησης μέσω Live Chat ή email."
}

export const X1ConfirmationText = {
	"en": "Do you want to use your Cypto balace in Casino?"
}

export const FundCashierContactSupportLink = { 
	"en": "Contact Support",
	"es": "PONTE EN CONTACTO CON EL SOPORTE"
}

export const FundCashierContactSupportText = { 
	"en": "to view more transactions",
	"es": "PARA VER MÁS TRANSACCIONES"
}

export const OpenWithdrawalForm = {  
	"en": "Open Withdrawal Form",
	"el": "Άνοιξε Φόρμα Ανάληψης",
	"de": "Auszahlungsformular öffnen",
	"es": "Abrir formulario de retiro"
}

export const NoLabel = { 
	"en": "No",
	"el": "Όχι",
	"de": "Nein",
	"es": "No"
}

export const SBBonusConfirmationText = { 
	"en": "Confirm Betting Bonus activation?",
	"el": "Επιβεβαίωση ενεργοποίησης του Μπόνους Στοιχήματος;",
	"de": "Bonusaktivierung bestätigen?",
	"es": "¿Confirmas la activación del bono de apuestas?"
}

export const ActivateNowLabel = { 	
	"en": "Activate now",
	"el": "Ενεργοποίηση τώρα",
	"de": "Jetzt Aktivieren",
	"es": "Activar Ahora"
}

export const ActivateLabel = {	 
	"en": "Activate",
	"el": "Ενεργοποίηση",
	"de": "Aktivieren",
	"es": "Activar"
}

export const PhoneLabelInformative = { 
	"en": "Payout will be done to account under phone number: {0}",
	"el": "Η πληρωμή θα γίνει στον λογαριασμό μέσω του τηλεφωνικού αριθμού: {0}",
	"de": "Auszahlung auf das Konto mit der Telefonnummer {0}",
	"es": "El pago se realizará a la cuenta con el número de teléfono: {0}"
}

// export const InteracTradeMark = {
// 	"en": "®Trade-mark of Interac Corp. Used under license.",
// 	"el": "®Trade-mark of Interac Corp. Used under license.",
// 	"de": "®Handelsmarke der Interac Corp. Wird unter Lizenz verwendet.",
// 	"es": "®Marca comercial de Interac Corp. Utilizada bajo licencia."
// }

export const RemoveCardConfirm = { 
	"en": "Are you sure you want to remove the currently selected credit card?",
	"el": "Σίγουρα θέλεις να διαγράψεις την επιλεγμένη πιστωτική κάρτα;",
	"de": "Bist du sicher, dass du die aktuell ausgewählte Kreditkarte entfernen möchtest?",
	"es": "¿Estás seguro de que deseas eliminar la tarjeta de crédito seleccionada actualmente?"
}

export const RemoveCard = { 
	"en": "Remove card",
	"el": "Διαγραφή κάρτας",
	"de": "Karte entfernen",
	"es": "Eliminar tarjeta"
}

export const NewCard = { 
	"en": "New card",
	"el": "Νέα κάρτα",
	"de": "Neue Karte",
	"es": "Nueva tarjeta"
}

export const NameOnCard = { 
	"en": "Name on card",
	"el": "Ονοματεπώνυμο κατόχου",
	"de": "Name auf der Karte",
	"es": "Nombre en tarjeta"
}

export const NameLabel = { 
	"en": "Name on card",
	"el": "Ονοματεπώνυμο κατόχου",
	"de": "Name auf der Karte",
	"es": "Nombre en tarjeta"
}

export const OpenMBAccLink = { 
	"en": "Open a MuchBetter account.",
	"el": "Δημιούργησε λογαριασμό MuchBetter.",
	"de": "Erstelle ein MuchBetter Konto.",
	"es": "Abre una cuenta MuchBetter."
}

export const script_load_error = { 
	"en": "The specified script could not be loaded, possibly due to an internet connection failure.",
	"el": "Δεν ήταν εφικτό να φορτωθεί το κείμενο/η σελίδα, πιθανώς λόγω προβλήματος σύνδεσης στο διαδίκτυο.",
	"de": "Das angegebene Skript konnte nicht geladen werden, möglicherweise aufgrund einer Unterbrechung der Internetverbindung.",
	"es": "La secuencia de comandos especificada no se pudo cargar, posiblemente debido a un error de conexión a Internet."
}

export const withdraw_success_title = { 
	"en": "Withdrawal is initiated!",
	"el": "Το αίτημα ανάληψης δημιουργήθηκε!",
	"de": "Die Auszahlung ist eingeleitet!",
	"es": "¡Retirada iniciada!"
}

export const withdraw_failure_title = { 
	"en": "Withdrawal error!",
	"el": "Σφάλμα στο αίτημα ανάληψης!",
	"de": "Auszahlungsfehler!",
	"es": "¡Error de retirada!"
}

export const deposit_success_title = { 
	"en": "Deposit was successful!",
	"el": "Η κατάθεση ήταν επιτυχής!",
	"de": "Einzahlung war erfolgreich!",
	"es": "¡El depósito se ha realizado correctamente!"
}

export const deposit_failure_title = { 
	"en": "Deposit error!",
	"el": "Σφάλμα στην κατάθεση!",
	"de": "Fehler bei der Einzahlung!",
	"es": "¡Error de depósito!"
}

export const payment_failure = { 
	"en": "The transaction could not be completed. This may have happened due to a temporary connection problem or you have canceled it. If the problem persists, please contact support.",
	"el": "Η συναλλαγή δεν μπόρεσε να ολοκληρωθεί. Αυτό μπορεί να έχει συμβεί λόγω προσωρινού προβλήματος σύνδεσης ή λόγω ακύρωσης. Αν το πρόβλημα επιμείνει, επικοινώνησε με την Εξυπηρέτηση Πελατών.",
	"de": "Die Transaktion konnte nicht abgeschlossen werden. Möglicherweise ist dies auf ein vorübergehendes Verbindungsproblem zurückzuführen oder du hast die Transaktion abgebrochen. Wenn das Problem weiterhin besteht, wende dich bitte an den Kundendienst.",
	"es": "La transacción no se pudo completar. Esto puede haber sucedido debido a un problema de conexión temporal o lo has cancelado. Si el problema persiste, comunícate con el soporte."
}

export const payment_success = { 
	"en": "You can now continue your gameplay!",
	"el": "Μπορείς τώρα να συνεχίσεις το παιχνίδι σου!",
	"de": "Du kannst jetzt dein Spiel fortsetzen!",
	"es": "¡Ahora puedes continuar tu juego!"
}

export const AccountBalanceTooLow = { 
	"en": "The minimum amount for this payment method exceeds your account balance.",
	"el": "Το ελάχιστο ποσό για τη συγκεκριμένη μέθοδο πληρωμής υπερβαίνει το υπόλοιπο του λογαριασμού σου.",
	"de": "Der Mindestbetrag für diese Zahlungsmethode übersteigt deinen Kontostand.",
	"es": "La cantidad mínima para este método de pago excede el saldo de tu cuenta."
}

export const CCJurisWarning_Text = {
	"en": "Online gaming may be illegal in the jurisdiction in which you are located, if so, you are not authorised to use your Payment card to complete this transaction.",
	"de": "Wo du dich befindest, untersagt die Rechtslage möglicherweise online Glücksspiel. Wenn dies der Falls ist, bist du nicht berechtigt, die Transaktion mit deiner Zahlungskarte abzuschließen.",
	"tr": "İnternet oyunları, bulunduğunuz bölgede yasa dışı olabilir, eğer öyleyse, bu işlemi tamamlamak için Ödeme kartınızı kullanmaya yetkiniz yoktur.",
	"es": "Los juegos de Internet pueden ser ilegales en la jurisdicción en la que te encuentras; de ser así, no estás autorizado a usar tu tarjeta de pago para completar esta transacción.",
	"el": "Τα online παιχνίδια ενδέχεται να μην είναι νόμιμα στην περιοχή που βρίσκεσαι. Αν ισχύει αυτό, τότε δεν μπορείς να χρησιμοποιήσεις την κάρτα πληρωμής σου για να ολοκληρώσεις αυτή τη συναλλαγή."
}

export const CCJurisWarning_Cbx = {
	"en": "I'm aware about the law concerning online gaming in my country.",
	"de": "Mir sind die Gesetze bezüglich Online-Glücksspiel in meinem Land bekannt.",
	"tr": "Ülkemdeki çevrimiçi oyunlarla ilgili yasaların farkındayım.",
	"es": "Soy consciente de la legislación concerniente a los juegos online en mi país.",
	"el": "Έχω ενημερωθεί για τη νομοθεσία που διέπει τα online παιχνίδια στη χώρα μου."
}

export const OpenProfileText = {
	"en": "Open User Profile",
	"el": "Πήγαινε στο Προφίλ",
	"de": "Benutzerprofil öffnen",
	"tr": "Kullanıcı Profilini Aç",
	"es": "Abre perfil de usuario"
}

export const OpenEditBankText = {
	"en": "Edit Bank Account",
	"de": "Bankkonto ändern",
	"tr": "Banka Hesabını Düzenle’yi Aç",
	"es": "Editar cuenta bancaria",
	"el": "Επεξεργασία Τραπεζικού Λογ/σμού"
}

export const TransListText = {
	"en": "30 latest transactions. To view more, please contact support.",
	"el": "30 τελευταίες συναλλαγές. Για να δεις περισσότερες, επικοινώνησε με την Εξυπηρέτηση Πελατών.",
	"de": "30 letzte Transaktionen. Um mehr zu sehen, wende dich bitte an den Kundendienst.",
	"es": "30 últimas transacciones. Para ver más, comunícate con el soporte."
}

// export const PBConversionLabel = {
// 	"en": "BonusPoints",
// 	"el": "REMOVED - DO NOT TRANSLATE",
// 	"de": "Bonuspunkte",
// 	"tr": "BonusPuanları",
// 	"es": "Puntos de bono"
// }

export const StatusLabel = { 
	"en": "Status",
	"el": "Κατάσταση",
	"de": "Status",
	"es": "Estado",
	"se": "STATUS",
	"tr": "DURUM"
}

export const ProviderLabel = { 
	"en": "PROVIDER",
	"el": "ΠΑΡΟΧΟΣ",
	"de": "ANBIETER",
	"es": "PROVEEDOR",
	"se": "LEVERANTÖR",
	"tr": "SAĞLAYICI"
}

export const BonusLabel = { 
	"en": "BONUS",
	"el": "ΜΠΟΝΟΥΣ",
	"de": "BONUS",
	"es": "BONO",
	"se": "BONUS",
	"tr": "BONUS"
}

// export const TypeLabel = {
// 	"en": "TYPE",
// 	"el": "ΤΥΠΟΣ",
// 	"de": "TYP",
// 	"es": "TIPO",
// 	"se": "TYP",
// 	"tr": "TİP"
// }

export const AmountLabel = { 
	"en": "Amount",
	"el": "Ποσο",
	"de": "Betrag",
	"es": "Cantidad",
	"se": "BELOPP",
	"tr": "TUTAR"
}

export const ShowBalanceInCurrencyText = { 	
	"en": "Show balance in {0}",
	"el": "Εμφάνιση του υπολοίπου μου σε {0}",
	"de": "Bilanz in {0} anzeigen",
	"es": "Mostrar saldo en {0}",
	"se": "Visa saldo i {0}",
	"tr": "Bakiyeyi {0} olarak göster"
}

export const ShowBalanceInCreditsText = { 
	"en": "Show balance in CREDITS",
	"el": "Εμφάνιση του υπολοίπου μου σε CREDITS",
	"de": "Bilanz in CREDITS anzeigen",
	"es": "Mostrar saldo en CREDITOS",
	"se": "Visa saldo i KREDITER",
	"tr": "Bakiyeyi KREDİ olarak göster"
}

export const CashableCreditsLabel = { 
	"en": "Cashable Credits",
	"el": "Μονάδες που πληρώνονται",
	"de": "Auszahlbare Credits",
	"es": "Créditos canjeables",
	"se": "Inlösbara krediter",
	"tr": "Paraya çevrilebilen krediler"
}

export const BonusCreditsRefundableLabel = { 
	"en": "Promotional Credits",
	"el": "Μονάδες Μπόνους",
	"de": "Promotions-Credits",
	"es": "Creditos promocionales",
	"se": "Kampanjkrediter",
	"tr": "Promosyon Kredileri"
}

export const BonusCreditsUseLikeCashLabel = { 
	"en": "Cashable Promotional Credits",
	"el": "Μονάδες Μπόνους (Αναλήψιμες t&c)",
	"de": "Auszahlbare Promotions-Credits",
	"es": "Créditos promocionales canjeables",
	"se": "Inlösbara kampanjkrediter",
	"tr": "Paraya Çevrilebilen Promosyon Kredileri"
}

export const CashableBalanceLabel = { 
	"en": "Cash",
	"el": "Μετρητά",
	"es": {
		"FOUR_SG": "Dinero",
		"FUND": "EFECTIVO"
	}
}

export const BonusBalanceRefundableLabel = { 
	"en": "Non-Cashable Bonus",
	"el": "Non-cashable Bonus",
	"de": "Nicht-auzhalbarer Bonus",
	"es": "Bono No Canjeable"
}

export const BonusBalanceUseLikeCashLabel = { 
	"en": "Cashable Bonus",
	"el": "Cashable Bonus",
	"de": "Auszahlbarer Bonus",
	"es": "Bono Canjeable"
}

export const NumWagerOder = { 
	"en": "Numbers show the wagering order",
	"el": "Οι αριθμοί δείχνουν τη σειρά στοιχηματισμού",
	"de": "Zahlen zeigen Reihenfolge der Wetten",
	"es": "Los números muestran el orden de apuesta"
}

export const SportsUserStatusLabel = { 
	"en": "Betting",
	"el": "Στοίχημα",
	"de": "Sportwetten",
	"tr": "Sporlar",
	"es": "Deportes"
}

export const TotalLabel = { 
	"en": "TOTAL",
	"el": "ΣΥΝΟΛΟ",
	"de": "SUMME",
	"es": "TOTAL",
	"se": "TOTALT",
	"tr": "TOPLAM"
}

export const WithdrawPendingLabel = { 
	"en": "Pending Withdrawals",
	"el": "Εκκρεμής ανάληψη",
	"de": "Anstehende Auszahlungen",
	"es": "Retiradas pendientes",
	"se": "Pågående uttag",
	"tr": "Bekleyen Para Çekmeler"
}

// export const UserStatusCreditsLabel = {
// 	"en": "Credit Status",
// 	"el": "Κατάσταση",
// 	"de": "Benutzerstatus",
// 	"es": "Estado de cuenta",
// 	"se": "Användarstatus",
// 	"tr": "Kullanıcı Durumu"
// }

export const UserStatusBalanceLabel = { 
	"en": "Balance",
	"el": "Υπόλοιπο",
	"de": "Kassenbestand",
	"es": "Balance"
}

export const DepositNowLabel = { 
	"en": "Deposit Now",
	"el": "Κάνε κατάθεση",
	"de": "Jetzt einzahlen",
	"tr": "Şimdi para yatır",
	"es": "Deposita Ahora"
}

export const DepositLabel = { 
	"FOUR_SG": {
		"en": "Deposit",
		"el": "Κατάθεση",
		"de": "Einzahlung",
		"es": "Depósito",
		"se": "Sätt in",
		"tr": "Para Yatır"
	},
	"FUND": {
		"en": "Deposit",
		"es": "Depositar"
	}
}

export const WithdrawLabel = { 
	"en": "Withdrawal",
	"el": "Ανάληψη",
	"de": "Auszahlen",
	"es": "Retirar",
	"se": "Ta ut",
	"tr": "Para Çek"
}

export const DepositPayoutLabel = { 
	"en": "Deposit/Payout",
	"el": "Κατάθεση/Ανάληψη",
	"de": "Einzalung/Auszahlung",
	"es": "Depósito / Pago"
}

export const HistoryLabel = { 
	"en": "History",
	"es": "Historial"
}

export const TransHistoryLabel = { 
	"en": "Transaction History",
	"el": "Ιστορικό Πληρωμών",
	"de": "Transaktionshistorie",
	"es": "Historial de transacciones",
	"se": "Transaktionshistorik",
	"tr": "İşlem Geçmişi"
}

export const CashierLabel = { 
	"en": "Cashier",
	"el": "Ταμείο",
	"de": "Kasse",
	"es": "Cajero",
	"se": "Kassa",
	"tr": "Vezne"
}

export const ConvertBonusPointsLabel = { 
	"en": "Convert to cash",
	"el": "Μετατροπή σε μετρητά",
	"de": "In Bargeld umrechnen",
	"es": "Convertir a efectivo",
	"se": "Konvertera till kontanter",
	"tr": "Nakde döndür"
}

export const Voucher = { 
	"en": "Voucher",
	"de": "Gutschein",
	"tr": "Kupon",
	"es": "Cupón",
	"el": "Voucher"
}

export const VerificationCode = { 
	"en": "Secure ID or Authentication Code",
	"el": "Ασφαλές αναγνωριστικό / Κωδικός ελέγχου ταυτότητάς",
	"de": "Sichere ID oder Authentifizierungscode",
	"tr": "Güvenli Kimlik veya Kimlik Doğrulama Kodu",
	"es": "Identificación segura o Código de autenticación"
}

export const DepositRedirectInfo = {
	"en": "* You will be transferred to the payment provider's webpage. If the new window does not open, please check your pop-up blocker.",
	"el": "* Θα μεταφερθείς στον ιστότοπο του παρόχου πληρωμής. Εάν δεν ανοίξει νέο παράθυρο, έλεγξε τον pop-up blocker σου.",
	"de": "* Du wirst auf die Webseite des Zahlungsanbieters weitergeleitet. Sollte sich das neue Fenster nicht öffnen, überprüfe bitte deinen Pop-up-Blocker.",
	"es": "* Serás transferido a la página web del proveedor de pagos. Si la nueva ventana no se abre, verifica tu bloqueador de ventanas emergentes."
}

export const ErrHasPendingWithdrawal = { 
	"en": "You already have one withdrawal request that has not been processed.",
	"el": "Έχεις ήδη ζητήσει μια ανάληψη που δεν έχει ολοκληρωθεί.",
	"de": "Du hast bereits eine Auszahlung beantragt, die noch nicht bearbeitet wurde.",
	"tr": "Henüz işleme koyulmamış bir para çekme talebiniz var.",
	"es": "Ya tienes una solicitud de extracción que no ha sido procesada."
}

export const Transfer = { 
	"en": "Transfer",
	"el": "Συμπλήρωσε το ποσό ανάληψης",
	"de": "Übertragung",
	"es": "Transferir",
	"se": "Överför",
	"tr": "Aktarım"
}

export const Available = { 
	"en": "Available",
	"el": "Διαθέσιμα",
	"de": "Verfügbar",
	"tr": "Mevcut",
	"es": "Disponible"
}

export const CardNumber = { 
	"en": "Card Number",
	"el": "Αριθμός κάρτας",
	"de": "Kartennummer",
	"es": "Número de tarjeta",
	"se": "Kortnummer",
	"tr": "Kart Numarası"
}

export const CcNumberLabel = { 
	"en": "Card Number",
	"el": "Αριθμός κάρτας",
	"de": "Kartennummer",
	"es": "Número de tarjeta",
	"se": "Kortnummer",
	"tr": "Kart Numarası"
}

export const SecurityCode = { 
	"en": "CVV",
	"el": "CVV",
	"de": "CVV",
	"es": "CVV"
}

export const ExpiryDate = { 
	"en": "Expiration Date",
	"el": "Ημερομηνία λήξης",
	"de": "Ablaufdatum",
	"es": "Fecha de caducidad"
}

// export const ExpiryDateLabel = {
// 	"en": "Expiration Date",
// 	"el": "Ημερομηνία λήξης",
// 	"de": "Ablaufdatum",
// 	"es": "Fecha de caducidad"
// }

export const Withdraw = {
	"en": "Withdrawal",
	"el": "Ανάληψη",
	"de": "Auszahlen",
	"es": "Retirar",
	"se": "Ta ut",
	"tr": "Para Çek"
}

export const Deposit = { 
	"en": "Deposit",
	"el": "Κατάθεση",
	"de": "Einzahlung",
	"es": "Depositar",
	"se": "Sätt in",
	"tr": "Para Yatır"
}

export const UseForWithdrawal = { 
	"en": "Use for withdrawal",
	"el": "Χρησιμοποίησε για ανάληψη",
	"de": "Benutze für Auszahlung",
	"es": "Uso para retirada"
}

export const SelectAsEasyPay = { 
	"en": "Select as Easy Pay",
	"el": "Επιλογή ως Easy Pay",
	"de": "Wähle Easy Pay",
	"es": "Selecciona como Easy Pay",
	"se": "Välj som Easy Pay",
	"tr": "Easy Pay seç"
}

// export const DepositInvalidTerms = {
// 	"en": "Deposit is disabled, please accept the new terms & conditions.",
// 	"el": "H κατάθεση είναι απενεργοποιημένη. Αποδέξου τους όρους και τις προϋποθέσεις.",
// 	"de": "Einzahlungen sind deaktiviert, bitte akzeptiere die neuen Geschäftsbedingungen.",
// 	"es": "El depósito está inhabilitado, acepta los nuevos términos y condiciones."
// }

export const DepositDisabled = { 
	"en": "Deposit is disabled.",
	"el": "Οι καταθέσεις είναι απενεργοποιημένες.",
	"de": "Die Einzahlung ist deaktiviert.",
	"tr": "Para yatırma devre dışı bırakıldı.",
	"es": "El depósito está deshabilitado."
}

// export const DepositDisabledNotValid = {
// 	"en": "Deposit is disabled, please validate your account.",
// 	"el": "Οι καταθέσεις είναι απενεργοποιημένες. Πρέπει να ταυτοποιήσεις το λογαριασμό σου.",
// 	"de": "Die Einzahlung ist deaktiviert. Bitte überprüfe dein Konto.",
// 	"tr": "Para yatırma devre dışı bırakıldı, lütfen hesabınızı doğrulayın.",
// 	"es": "El depósito está deshabilitado, por favor valida tu cuenta."
// }

export const PostalCodeMissing = { 
	"en": "Please insert your Postal Code in order to deposit.",
	"de": "Bitte gib deine Postleitzahl ein, um mit der Einzahlung fortzufahren.",
	"tr": "Lütfen para yatırmak için Posta Kodunuzu giriniz.",
	"es": "Por favor, introduce tu código postal para realizar depósito.",
	"el": "Θα πρέπει να εισάγεις τον Τ.Κ. σου για να μπορέσεις να καταθέσεις."
}

export const WithdrawEditBankAcc = { 
	"en": "This withdrawal method is disabled. Please edit your bank account details by visiting your Account and then select Edit Bank Account.",
	"el": "Η μέθοδος ανάληψης μέσω τραπέζης είναι απενεργοποιημένη. Ρύθμισε τις λεπτομέρειες του τραπεζικού σου λογαριασμού.",
	"de": "Diese Auszahlungsmethode ist deaktiviert. Bitte bearbeite deine Bankkontodaten indem du dich in dein Konto anmeldest und Bankkonto bearbeiten auswählst.",
	"tr": "Bu para çekme yöntemi devre dışı bırakıldı. Lütfen, Hesabınızı ziyaret ederek banka hesap ayrıntılarınızı düzenleyin ve ardından Banka Hesabını Düzenle ögesini seçin.",
	"es": "Este método de extracción está deshabilitado. Edita los detalles de tu cuenta bancaria visitando tu cuenta y luego selecciona Editar cuenta bancaria."
}

export const WithdrawDisabled = { 
	"en": "Withdrawal disabled",
	"el": "Η ανάληψη απενεργοποιήθηκε",
	"es": "Retirar deshabilitado"
}

export const WithdrawDisabledNotValid = {
	"en": "Please <a href=\"#\" onclick=\"EGOE.goTo('upload-docs')\">validate your account</a> to withdraw your winnings.",
	"el": "Οι αναλήψεις είναι απενεργοποιημένες. <a href=\"#\" onclick=\"EGOE.goTo('upload-docs')\">Πρέπει να ταυτοποιήσεις το λογαριασμό σου.</a>",
	"de": "Die Auszahlung ist deaktiviert. Bitte <a href=\"#\" onclick=\"EGOE.goTo('upload-docs')\">überprüfe dein Konto</a>.",
	"es": "Por favor, <a href=\"#\" onclick=\"EGOE.goTo('upload-docs')\">valida tu cuenta</a> para retirar tus ganancias",
}

// export const DepositPassive = {
// 	"en": "Player exclusion active, deposit is disabled. Check your limits!",
// 	"el": "Αποκλεισμός παίχτη ενεργός. Η κατάθεση είναι απενεργοποιημένη. Έλεγξε τα όρια!",
// 	"de": "Spielerausschluss aktiv, die Einzahlung ist blockiert. Überprüfe deine Limits!",
// 	"es": "Exclusión de jugadores activa, depósito deshabilitado. ¡Revisa tus límites!",
// 	"se": "Spelaruteslutning är aktiv, insättning är inaktiverad. Kolla dina gränser!",
// 	"tr": "Kendini uzak tutma etkin, para yatırma devre dışı. Sınırlarınızı kontrol edin!"
// }

// export const enterBlueberryId = {
// 	"en": "Please enter your Blueberry ID to use this payment method.",
// 	"el": "Θα πρέπει να εισάγεις τη διεύθυνση e-mail ή το Blueberry ID για να χρησιμοποιήσεις αυτή την μέθοδο πληρωμής.",
// 	"de": "Gib bitte deine Blueberry-ID ein, um diese Zahlungsmethode zu benutzen.",
// 	"es": "Por favor ingresa tu identificación de Blueberry para utilizar este método de pago.",
// 	"se": "Vänligen ange ditt Blueberry-ID för att använda denna betalningsmetod.",
// 	"tr": "Bu ödeme yöntemini kullanmak için lütfen Blueberry ID’nizi girin."
// }

// export const enterEcoPayzNumber = {
// 	"en": "Please enter your ecoPayz Number to use this payment method.",
// 	"el": "Θα πρέπει να εισάγεις τον αριθμό ecoPayz για να χρησιμοποιήσεις τη συγκεκριμένη μέθοδο πληρωμής.",
// 	"de": "Gib bitte deine ecoPayz-Nummer ein, um diese Zahlungsmethode zu verwenden.",
// 	"tr": "Bu ödeme yöntemini kullanmak için lütfen ecoPayz Numaranızı girin.",
// 	"es": "Por favor ingresa tu Número de ecoPayz para usar este medio de pago."
// }

export const requested = { 
	"en": "Requested",
	"el": "Αίτημα σε εξέλιξη",
	"de": "Angefordert",
	"es": "Solicitado",
	"se": "Begärd",
	"tr": "İstendi"
}

export const approved = { 
	"en": "Approved",
	"el": "Εγκεκριμένη",
	"de": "Zugelassen",
	"es": "Aprobado",
	"se": "Godkänd",
	"tr": "Onaylandı"
}

export const canceled = { 
	"en": "Cancelled",
	"el": "Ακυρώθηκε",
	"de": "Abgebrochen",
	"es": "Cancelado",
	"se": "Avbruten",
	"tr": "İptal Edildi"
}

export const Returned = {
	"en": 'Returned',
	"es": 'Devuelto',
};

export const Declined = {
	"en": 'Declined',
	"es": 'Rechazada',
};

export const pending = { 
	"en": "Pending",
	"el": "Εκκρεμής",
	"de": "Anstehend",
	"es": "Pendiente",
	"se": "Pågående",
	"tr": "Beklemede"
}

export const processed = { 
	"en": "OK",
	"el": "OK",
	"de": "OK",
	"es": "OK"
}

export const error = { 
	"en": "Error",
	"el": "Σφάλμα",
	"de": "Fehler",
	"es": "Error"
}

export const processedonprovider = { 
	"en": "Processed on provider",
	"el": "Επεξεργασμένο από τον πάροχο",
	"de": "Beim Dienstleister bearbeitet",
	"es": "Procesado al proveedor",
	"se": "Bearbetad av leverantören",
	"tr": "Sağlayıcıda işlendi"
}

export const _void = {
	"en": "Void",
	"el": "Άκυρο",
	"de": "Leer",
	"es": "No válido",
	"se": "Ogiltig",
	"tr": "Geçersiz"
}

export const waitinguser = {
	"en": "Waiting user",
	"el": "Αναμονή χρήστη",
	"de": "Warte auf Benutzer",
	"es": "Usuario en espera"
}

export const timeout = {
	"en": "Timeout",
	"el": "Τέλος χρόνου",
	"de": "Auszeit",
	"es": "Tiempo finalizado",
	"se": "Spelavbrott",
	"tr": "Zaman Aşımı"
}

export const closed = {
	"en": "Closed",
	"el": "Κλειστό",
	"de": "Geschlossen",
	"es": "Cerrado",
	"se": "Avslutad",
	"tr": "Kapandı"
}

export const rollbackbyprovider = {
	"en": "Rollback by provider",
	"el": "Επαναφορά από τον πάροχο",
	"de": "Rollback durch den Dienstleister",
	"es": "Rollback por proveedor",
	"se": "Återställning av leverantör",
	"tr": "Sağlayıcı tarafından geri alma"
}

