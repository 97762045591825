export const MaintenanceTitle = { 
	"FOUR_SG": {
		"en": "Maintenance"
	},
	"FUND": {
		"en": "Scheduled Maintenance",
		"es": "Mantenimiento Programado"
	}
}

export const MaintenanceText = { 
	"FOUR_SG": {
		"en": "Website maintenance is scheduled<br>from {dateFrom} to {dateTo}."
	},
	"FUND": {
		"en": "Heads up Fundaloris! Some essential maintenance is planned from<br>{dateFrom} to {dateTo}.",
		"es": "¡Atención Fundaloris! Algunas tareas esenciales de mantenimiento<br>están previstas desde el {dateFrom} hasta el {dateTo}."
	}
}