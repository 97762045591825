import model from "@js/model.js";
import { setLocationSearch, getParameterByName, clearLocationSearch, toArray, isObject, addQueryStringValues, getKeyByValue, arrayFirst, getLocationSearch, propNameLowerCase, addDirPath, toHex } from "@helpers/utils.js"
import { watch, reactive, nextTick } from "vue"

import cookieHelper from "@helpers/cookie-helper.js";
import Enum from "@shared/enum.js";
import { UserDisabled, ValidateAccLabel } from "@lang/account.js";
import { error, FillAllProfileInfo } from "@lang/cashier.js";
import { JoinNowLabel } from "@lang/register.js";
import { AttentionLabel, Cancel, Close, Continue, English, GameNotAvailOnDevice, Greek, Info, NetworkConnError, NetworkConnResolved, OK, PlayNowLabel, PopularLabel, SeeMoreLabel, Send, Submit, Today, ViewAll, Yesterday, cancel, gameplayResumed_text, gameplayResumed_title, slotGame_notEnded_text, warning } from "@lang/user.js";
import { SBInvalidUserText } from "@lang/spaceBus.js";
import dynamicImportLoad from "@helpers/dynamic-import-load.js";
import { ReceiptOfYourPaymentText } from "@lang/paymentStatuses.js";

export default {
	initUser(){
		if(typeof model.gameHistoryEnabled !== 'boolean') model.gameHistoryEnabled = true;

		model.isPageLoad = false;
		model.originalLocationSearch = location.search;

		model.initialLocationSearch = getLocationSearch();
		if(isObject(model.initialLocationSearch) && !Object.keys(model.initialLocationSearch).length) model.initialLocationSearch = null;

		model.pushLang({
			ReceiptOfYourPaymentText,
			UserDisabled, 
			ValidateAccLabel,
			error, 
			JoinNowLabel, 
			ViewAll, 
			PlayNowLabel, 
			SeeMoreLabel,
			SBInvalidUserText,
			PopularLabel,
			AttentionLabel,
			GameNotAvailOnDevice,
			Submit,
			Continue,
			NetworkConnResolved,
			NetworkConnError,
			Today,
			Yesterday,
			English,
			Greek,
			slotGame_notEnded_text,
			gameplayResumed_title,
			gameplayResumed_text,
			warning,
			OK,
			Cancel,
			cancel,
			Close,
			Send,
			Info,
		});

		model.wheelDisplayed = false;
		model.observables.wofDisplayed = false;
		model.observables.wofDisplayable = false;
		model.observables.wofPromoId = false;

		model.observables.userCountry = null;
		model.observables.guestCountryInit = false;

		model.observables.forceNavHidden = false;
		model.observables.subNavAttached = false;

		model.observables.subNavPlaceholderSlim = false;

		model.observables.regConfirmShown = false;
		model.observables.selectedMiniGame = null;
		model.observables.userInteracted = false;
		model.observables.storedUsername = null;

		model.editableProfileFields = model.redeemLangObj({
			FUND_COM: ['State', 'PostalCode', 'Address1', 'City'],
			FUND_MX: ['PostalCode', 'Address1', 'City'],
		});
		model.observables.allProfileFieldsInserted = false;
		model.on('LogoutComplete', () => model.observables.allProfileFieldsInserted = false);

		(() => {
			updateState();
			model.on('LoginComplete', updateState);
			function updateState(){
				model.observables.credentialsStored = cookieHelper.get('credentials-stored') == 'true' || localStorage.getItem('credentials-stored') == 'true';
				model.observables.autoLogin = model.observables.credentialsStored;
			}
		})();

		if(!model.isApp){
			model.observables.cookieMsgShown = true;
			watch(() => model.observables.cookieMsgShown, bool => {
				$('html')[bool ? 'addClass' : 'removeClass']('cookie-msg-shown');
			}, {immediate: true});
			if (!model.hasCookieLaw || cookieHelper.get('cookie_law_informed') == 1) model.observables.cookieMsgShown = false;
		}

		watch(() => model.observables.selectedMiniGame, bool => {
			bool ? model.dispatchEvent('LockBodyScroll') : model.dispatchEvent('UnlockBodyScroll');
		});

		model.observables.lobbyHelper = {
			loaded: false,
			loading: false,
			response: null,
		};

		model.sideMenuVisible = false;

		let prevCat = sessionStorage.getItem('PrevCategory');
		if(model.isUI('FUND') && prevCat) setLocationSearch(null, prevCat, false);

		if(!sessionStorage.getItem('activation-code')){
			let actvationAt = getParameterByName('at') || 'c';
			actvationAt = Enum.CLIENT_ACTIV_CODES[actvationAt];
			sessionStorage.setItem('activation-code', actvationAt);
			clearLocationSearch('at');
		}

		(() => {
			let obs = reactive({
				left: {value: false},
				right: {value: false},
			});
			model.mobileNavDisplayProps = obs;
			model.getMobileNavDisplayProps = () => {
				return {
					'mobile-menu-opened-left': obs.left.value,
					'mobile-menu-opened-right': obs.right.value,
				}
			};
		})();

		if(typeof model.loginEnabled !== 'boolean') model.loginEnabled = true;
		if(typeof model.subDomainPathIndex !== 'number') model.subDomainPathIndex = 0;

		model.observables.navCountrySelectionShown = false;

		model.restrictedRegions = model.restrictedRegions || {};

		model.countryList = [];

		model.observables.deposits = 0;

		//temp solution
		model.deposits = () => {return model.observables.deposits;}

		model.on('LogoutComplete', () => model.observables.deposits = 0);

		if(!Array.isArray(model.providerConfig)){
			model.providerConfig = [];
		}
		
		model.restrictLoginByCC = toArray(model.restrictLoginByCC);
		model.loginRestricted = false;

		if(model.oneSignalAppId){
			window.OneSignal = window.OneSignal || [];
			OneSignal.push(() => {
				OneSignal.on('subscriptionChange', bool => {
					model.dispatchEvent('OneSignalSubUpdate', {
						subscribed: bool,
					});
				});
	
				OneSignal.on('notificationPermissionChange', permissionChange => {
					model.dispatchEvent('OneSignalPermUpdate', {
						permission: permissionChange.to,
					});
				});
				
				OneSignal.init({
					appId: model.oneSignalAppId,
					safari_web_id: model.oneSignalSafariAppId,
					promptOptions: {
						slidedown: {
							enabled: true,
							autoPrompt: true,
							timeDelay: 5,
						},
					},
				});

				if(!model.isMobile) return;
				
				let $notifBell = null;

				let int = setInterval(() => {
					$notifBell = $('#onesignal-bell-launcher');
					if(!$notifBell.length) return;

					clearInterval(int);
					
					onScroll();
					$(document).on('scroll', onScroll);

					setTimeout(() => onScroll(), 100);
					model.on('ForcedObsLoaded', () => setTimeout(() => onScroll(), 100));
					
					function onScroll(){
						$notifBell.css('transition', 'bottom .25s');
						$notifBell.css('bottom', '112px');
					}

					sideMenuVisible(model.sideMenuVisible);
					model.on('SideMenuVisible', e => sideMenuVisible(e.bool));

					function sideMenuVisible(bool){
						$notifBell[bool ? 'hide' : 'show']();
					}
				}, 500);
			});
		}

		model.recommendationCode = getParameterByName("friendcode") || model.recommendationCode;

		saveInfoFromQS.call(this);

		if(model.currencies){
			for(var key in model.currencies){
				model.currencies[key] = $.extend({
					centsSeparator: ',',
					thousandsSeparator: '.',
				}, model.currencies[key]);
			}
		}

		if(model.isView(Enum.Views.TC, Enum.Views.BTC, Enum.Views.FREE_BET)) return;

		var activationCode = getParameterByName("a");
		var passwordReset = getParameterByName("pr");

		model.showOnStart = getParameterByName("show");

		if(!model.showOnStart && sessionStorage.getItem('showOnLoad')){
			model.showOnStart = sessionStorage.getItem('showOnLoad');
		}

		sessionStorage.removeItem('showOnLoad');

		this.whenAppReady(() => {
			model.on('ServerResponseSuccess', async e => {
				var action = e.action;
				switch(action.actionType){
					case 'BonusMultiplierWheel' : 
						this.handle_bonusPointMultiplier(action);
						break;
					case "GetPlayerInfo":
						await this.handleGetPlayerInfo(action);
						this.handle_keepAlive(action);
						break;
					case "GameLog":
						this.handle_GameLog(action);
						break;
					case 'NetEntLogin' : 
						this.handle_netEntLogin(action);
						break;
					case 'PreviewGame' : 
						this.handle_gamePreview(action);
						break;
					case 'BetsoftStart' : 
						this.handleBetsoftStart(action);
						break;
					case 'SBActivateBonus' : 
						this.handleSBActivateBonus(action);
						break;
				}
			});
		});

		if(model.viewExpectingGames) this.whenAppReady(() => handlePageLoad.call(this));
		else nextTick(() => handlePageLoad.call(this));

		function handlePageLoad(){
			if(activationCode) this.sendActivationRequest(activationCode, false);
			else if(passwordReset) this.startPasswordReset(passwordReset)
			else model.uToken = this.getTokenFromStorage();

			if(!model.uToken){
				model.dispatchEvent('LogoutComplete', {isPageLoad: true});

				if(!model.showOnStart && model.observables.autoLogin){
					this.postFlutterMessage('Flutter_WebView_RequestLogin');
					return;
				}

				switch(model.showOnStart){
					case Enum.UIContexts.LOGIN : 
					case Enum.UIContexts.REGISTER : 
					case Enum.UIContexts.SMS_ACTIVATION_FORM : 
					case Enum.UIContexts.DEPOSIT : 
						this.goTo(model.showOnStart); 
						break;
				}
				return;
			}

			onPageReload.call(this);
		}

		if(activationCode || passwordReset || model.showOnStart) clearLocationSearch('a', 'pr', 'show');

		setupDomainAndLangEvents.call(this);
		requestUserGuestCountry.call(this);
		
		var tokenParam = getParameterByName("t");
		model.wasTPFetched = false;
		if(tokenParam){
			model.wasTPFetched = true;
			model.uToken = tokenParam;
			this.setTokenToStorage(model.uToken);
			clearLocationSearch('t');
		}

		var methodList = model.paymentConfig.methodList;
		for(var i = 0, j = methodList.length; i < j; i++){
			var item = methodList[i];
			if(!Array.isArray(item.id))
				continue;

			item.id.forEach(id => {
				var cloned = $.extend(true, {}, item);
				cloned.id = id;
				methodList.push(cloned);
				j++;
			});

			methodList.splice(i, 1);
			i--;
			j--;
		}

		if(model.isProd){
			for(var i = 0, j = model.providerConfig.length; i < j; i++){
				var item = model.providerConfig[i];
				if(typeof item.prod === 'boolean' && !item.prod){
					model.providerConfig.splice(i, 1);
					i--;
					j--;
				}
			}
		}

		model.promoGameGroup = {};
		for(var i = 0, j = model.providerConfig.length; i < j; i++){
			var item = model.providerConfig[i];

			//tmp fix, should probably consider emptying the array with toArray if the value is falsy
			if(!item.allowedByCCode) item.allowedByCCode = undefined;
			if(!item.notAllowedByCCode) item.notAllowedByCCode = undefined;

			item.gameGroups = toArray(item.gameGroups);
			item.allowedByCCode = toArray(item.allowedByCCode);
			item.notAllowedByCCode = toArray(item.notAllowedByCCode);
			
			model.promoGameGroup[item.id || item.label] = {
				ids: item.gameGroups,
				path: item.assetPath,
				siteIDs: item.siteIDs,
			};
		}

		model.on('LoginComplete', 'LogoutComplete', 'GetPlayerInfoLoaded', 'PromoInfoPageReady', 'PromoListLoaded', 'HandleBonusMultiplierWheel', () => {
			let wofLaunchBtns = [];

			model.promos.all.forEach(promo => {
				wofLaunchBtns = wofLaunchBtns.concat(promo.ctaBtns.filter(b => b.id === 'WofLaunch' && isObject(b.visible)));
			});

			wofLaunchBtns.forEach(btn => {
				if(!model.logged) {
					btn.visible.value = true;
					return;
				}

				btn.visible.value = this.getWOFPromo(false) || this.getWOFPromo(true) || this.getFreeGamesPromo();
			});

			model.dispatchEvent('CTABtnsUpdated');
		});

		model.on('LoginComplete', 'LogoutComplete', 'SetLang', () => {
			model.observables.lobbyHelper.response = null;
			model.observables.lobbyHelper.loaded = false;
			model.observables.lobbyHelper.loading = false;
		});

		// if(model.isUI('FUND')){
		// 	model.on('LoginComplete', 'ModalClosed', (e) => {
		// 		if(!model.logged) return;
				
		// 		if(e.eventName === 'ModalClosed' && e.modal?.uiContext !== Enum.UIContexts.LOGIN) return;
	
		// 		if(!model.rememberCatsOnLastVisit) return;
					
		// 		let catOnLastVisit = localStorage.getItem('cat-remembered-on-last-visit');
		// 		if(!catOnLastVisit) return;

		// 		if(sessionStorage.getItem('ignore-cat-remembered-on-last-visit')){
		// 			sessionStorage.removeItem('ignore-cat-remembered-on-last-visit')
		// 			return;
		// 		}
			
		// 		catOnLastVisit = JSON.parse(catOnLastVisit);
		// 		if(model.currCatIds[`level${catOnLastVisit.levelNum}`] === catOnLastVisit.id || this.getMultiPagePath()) return;
	
		// 		let tmp = {};
		// 		tmp[`level${catOnLastVisit.levelNum}`] = catOnLastVisit.id;
		// 		this.gotoCategory(tmp);
		// 	});
		// }
	
		model.on('GetPlayerInfoLoaded', 'LoginComplete', () => {
			if(!model.isView(Enum.Views.MAIN)) return;

			let wheelPromo = this.getWOFPromo();
			if(!wheelPromo?.id) return;

			let promoId = wheelPromo.id;
			
			let minigame = model.minigamesConfig?.find(x => x.promoIDs?.includes?.(promoId) || x.promoIDs == promoId);
			if(!minigame) return;

			let iframeUrl = minigame.iframeUrl;
			if(Array.isArray(iframeUrl)){
				let country = model.userProfile.country;
				if(getParameterByName('test-country')) country = getParameterByName('test-country');

				let targetItem = iframeUrl.find(item => Array.isArray(item.countries) && item.countries.find(c => c.toUpperCase() === country.toUpperCase()));
				if(!targetItem) targetItem = iframeUrl.find(item => item.countries == 'all');
				iframeUrl = targetItem.path;
			}

			model.observables.selectedMiniGame = {
				iframeUrl: addQueryStringValues(iframeUrl, {
					ui: model.siteKey, 
					lang: model.lang,
					currency: model.getCurrency().code.toLowerCase(),
					currencySymbol: model.getCurrency().symbol_native,
				}),
				promoId,
			};
		});

		this.model.on('CloseMiniGame', 'LogoutComplete', () => this.model.observables.selectedMiniGame = null);

		model.on('ExternalTransaction', async e => {
			let action = e.action;
			if(typeof action?.provider !== 'string' || !action?.transactionId) return;

			await this.getSoarPayAPIs();
			await this.getSoarPayConfig();

			let logoUrl = null;

			let providerConfigKey = Object.keys(model.soarPayConfig.methods.general).find(key => key.toUpperCase().split('-').includes(action.provider.toUpperCase()));
			if(providerConfigKey) logoUrl = model.soarPayConfig.methods.general[providerConfigKey].url;

			let popup = model.getCurrContext() !== Enum.UIContexts.DEPOSIT_SINGLE_PAGE;

			console.log('external transaction');
			this.processPaymentReceipt({
				transactionId: action.transactionId,
				apiPropName: 'soarPayAPI',
				logoUrl,
				popup,
				paymentType: Enum.PaymentTypes.DEPOSIT,
				global: true,
			});

			if(popup){
				model.on('ShowPaymentReceipt', async e => {
					e.dispose();
					model.cashierExternalTransData = {
						logoUrl: e.logoUrl, 
						paymentType: e.paymentType, 
						data: e.data, 
						closeModal: 'all',
						deposits: model.observables.deposits,
					};
					this.goTo(Enum.UIContexts.DEPOSIT_SINGLE_PAGE, true);
				});
			}
		});

		model.on('LoginComplete', e => {
			if(!e.username || !e.password) return;
			this.postFlutterMessage('Flutter_WebView_StoreCredentials', {username: e.username, password: e.password});
		});

		model.on('RegistrationComplete', e => {
			if(!e.request?.userName || !e.request?.password) return;
			this.postFlutterMessage('Flutter_WebView_StoreCredentials', {username: e.request.userName, password: e.request.password});
		});

		// this.handleTemporaryParams();
	},
	getRecommendationCodeLink(){
		let url = window.location.origin;
		if(url.includes('https://app.')) url = url.replace('https://app.', 'https://www.');
		return addQueryStringValues(url, {
			friendcode: this.getRecommendationCode(),
		});
	},
	notifyFillAllProfileFields(){
		if(model.observables.allProfileFieldsInserted) return false;

		model.pushLang({FillAllProfileInfo});

		this.showNotification({
			title: model.getStr('Info'),
			text: model.getStr('FillAllProfileInfo'),
			buttons: [
				{
					type: Enum.ButtonTypes.SUCCESS,
					label: 'OK',
					onClick: () => {
						model.profileScrollToEmptyField = true;
						this.goTo(Enum.UIContexts.USER_PROFILE);
					},
				},
			],
		});
		return true;
	},
	waitForGuestCountryResponse(){
		return new Promise(resolve => {
			if(model.observables.guestCountryInit){
				resolve();
				return;
			}

			let unwatch = watch(() => model.observables.guestCountryInit, bool => {
				if(!bool) return;
				resolve();
				unwatch();
			});
		});
	},
	async postFlutterMessage(){
		if(!model.isApp) return false;

		let functName = arguments[0];
		let arg = JSON.stringify(arguments[1] || {});

		let passed = true;

		if(!window[functName]){
			console.log(
				`%cFlutter function: "${functName}" | argument sent: ${arg}`, 
				'background: #d111a1; color: #fff; padding: 5px 10px;',
			);
			passed = false;
		}
		else window[functName].postMessage(arg);

		if(!model.isProd){
			await (async () => {
				if(!functName.includes('RequestLogin')) return;
		
				let testCredentials = getParameterByName('test-credentials');
				if(!testCredentials) return;
		
				testCredentials = testCredentials.split('|');
				let testUsername = testCredentials[0];
				let testPassword = testCredentials[1];
		
				let [testEncryptedPass] = await this.encryptPassword(testPassword);
				EGOE.loginUser(testUsername, testEncryptedPass);
				// EGOE.loginUser(testUsername, testPassword);
			})();
		}

		return passed;
	},
	getRecommendationCode(){
		return toHex(model.userId);
	},
	userBusiness(global){
		return this.getBusiness('__userBusiness', () => import("../business/user-business.js"), global);
	},
	requestLobbyHelper(){
		return new Promise(resolve => {
			if(this.lobbyHelperBusiness){
				inner.call(this);
				return;
			}

			dynamicImportLoad(() => import('./../business/other/lobby-helper-business.js'), false).then(module => {
				this.lobbyHelperBusiness = new module.default();
				inner.call(this);
			});

			function inner(){
				let url = this.model.urls.lobbyHelper;
				if(typeof url !== 'string') return;
	
				if(model.observables.lobbyHelper.loaded) {
					resolve();
					return;
				}
	
				if(model.observables.lobbyHelper.loading){
					let unwatch = watch(() => model.observables.lobbyHelper.loaded, bool => {
						if(!bool) return;
	
						unwatch();
						resolve();
					});
					return;
				}
		
				model.observables.lobbyHelper.loading = true;
	
				url = url.replace('{userId}', model.userId || 0);
				url = addQueryStringValues(url, {
					request: JSON.stringify({
						country: model.subDomain,
						siteId: (getKeyByValue(Enum.SiteIDs, model.siteId) + '').toUpperCase(),
					}),
				});
	
				this.sendJSONRequest({url, global: false}).then(response => {
					model.observables.lobbyHelper.response = response;
					model.observables.lobbyHelper.loaded = true;
					model.observables.lobbyHelper.loading = false;
					this.fetchPreparedGames(() => resolve());
				});
			}
		});
	},
	openWOF(){
		if(!model.logged){
			this.goTo(Enum.UIContexts.LOGIN);
			return;
		}

		if(this.getWOFPromo(false)) {
			model.dispatchEvent('ShowWOF');
			return;
		}

		let activatedWheel = this.getWOFPromo(true) || this.getFreeGamesPromo();
		activatedWheel && this.startGame(activatedWheel.gameInstanceId);
	},
	getWOFPromo(activated){
		if(typeof activated !== 'boolean') activated = false;

		return arrayFirst(model.promotions?.playerPromotion, promo => {
			return ([Enum.PlayerPromotions.Types.WOF, Enum.PlayerPromotions.Types.FreeGamesWheel, Enum.PlayerPromotions.Types.MixedWheel].includes(promo.type)) && (!activated ? !promo.activated : promo.activated);
		});
	},
	getFreeGamesPromo(){
		return arrayFirst(model.promotions?.playerPromotion, promo => {
			return promo.type == Enum.PlayerPromotions.Types.FreeGames && promo.activated;
		});
	},
	selectLangByCCode(ccode){
		var targetItem = model.langComponentObs.supportedLangs().find(item => (item.ccode + '').toLowerCase() === (ccode + '').toLowerCase());
		if(targetItem) model.langComponentObs.langObs.selectedLang = targetItem.key;
	},
	getDepositDisabledMsg(){
		return new Promise(resolve => {
			dynamicImportLoad(async () => {
				const { DepositDisabled } = await import("@lang/cashier.js");
				model.pushLang({ DepositDisabled });
			}).then(() => resolve(model.getStr('DepositDisabled')));
		});
	},
	getWithdrawalDisabledMsg(global){
		return new Promise(resolve => {
			dynamicImportLoad(async () => {
				const { ErrHasPendingWithdrawal, WithdrawDisabled, PleaseContactSupport, WithdrawDisabledNotValid } = await import("@lang/cashier.js");
				model.pushLang({ ErrHasPendingWithdrawal, WithdrawDisabled, PleaseContactSupport, WithdrawDisabledNotValid });
			}, global).then(() => {
				if(model.hasPendingWithdrawal()) {
					resolve(model.getStr('ErrHasPendingWithdrawal'));
					return;
				}

				if(model.userActive()){
					resolve(model.getStr('WithdrawDisabledNotValid'));
					return;
				}

				resolve({
					title: model.getStr('WithdrawDisabled'),
					text: model.getStr('PleaseContactSupport'),
				});
			});
		});
	},
	async showSpaceBusIllegitimateMsg(){
		(await this.userBusiness()).showSpaceBusIllegitimateMsg.call(this);
	},
	setTokenToStorage(uToken){
		window.sessionStorage.setItem('uToken', uToken);
		if(model.userProfile?.userId) cookieHelper.set('player_id', model.userProfile.userId, 365);
	},
	removeTokenFromStorage(){
		window.sessionStorage.removeItem('uToken');
	},
	getTokenFromStorage(){
		return window.sessionStorage.getItem('uToken');
	},
	sendSBActivateBonus(id){
		this.sendRequest('SBActivateBonus', {id}, () => this.sendGetPlayerInfo());
	},
	sendPreviewGame(gsid, callback){
		this.sendRequest('PreviewGame', {gsid}, callback);
	},
	sendGetPlayerInfo (params, global) {
		if(typeof global !== 'boolean') global = true;

		return new Promise(resolve => {
			this.sendRequest('GetPlayerInfo', params, (action, xml) => resolve({action, xml}), {global});
		});
	},
	sendLoadUser(callback, global){
		if(typeof global !== 'boolean') global = true;
		
		this.sendRequest("UserDataManipulation", {type: 'LoadUser'}, callback, {global});
	},
	sendGameLogRequest (params, callback) {
		this.sendRequest("GameLog", params, callback, {global: false});
	},
	handle_gamePreview(action){
		var main = arrayFirst(action.messages.message, msg => msg.type == 'Main' && msg.previewGame);
		if(!main) return;

		model.subGames = toArray(main.previewGame.subGames);
		model.dispatchEvent('HandlePreviewGame');
	},
	async handleGetPlayerInfo() {
		return await (await this.userBusiness()).handleGetPlayerInfo.apply(this, arguments);
	},
	updateLoginStatus(action){
		if(!action) return;
		model.uToken = action.uToken;
		model.logged = Boolean(model.uToken);
		model.userId = action.userId;
		model.logged && this.setTokenToStorage(model.uToken);
	},
	sendNetEntLogin(callback){
		this.sendRequest('NetEntLogin', null, callback);
	},
	sendBetsoftStart(extGameId, callback){
		this.sendRequest('BetsoftStart', {extGameId}, callback);
	},
	acceptTermsAndCond() {
		this.sendRequest("TermsAcceptance");
	},
	acceptPrivacyPolicy() {
		this.sendRequest("PrivacyAcceptance");
	},
	isGameResumeRequired(){
		if(!this.hasGameResume()) return false;

		var game = this.getGameByID(model.gameResumeId);
		return Boolean(game && game.productID == Enum.ProductIDs.EgoeSlot);
	},
	hasGameResume(){
		return typeof model.gameResumeId !== 'undefined';
	},
	setupQueryValueCookie(queryName, days, clear){
		if(typeof clear !== 'boolean') clear = true;
		var queryValue = getParameterByName(queryName);
		if(queryValue){
			cookieHelper.set(queryName, queryValue, days || 30);
			clear && clearLocationSearch(queryName);
		}
	},
	setupQueryValueSessionItem(queryName){
		var queryValue = getParameterByName(queryName);
		if(queryValue) sessionStorage.setItem(queryName, queryValue);
	},
	setupPromoCodeFromUrl(){
		var pcUrl = getParameterByName('pc') || cookieHelper.get('pc');
		if(!pcUrl) return;

		model.promoCode = pcUrl;
		cookieHelper.set('pc', pcUrl, 30);
		model.promoCodeFromUrl = pcUrl;
	},
	sendWOFRequest(promoId, deactivate){
		this.sendRequest('BonusMultiplierWheel', {
			promoId: promoId,
			deactivate: deactivate,
		});
	},
	sendCountryListRequest(callback) {
		/*
			For performance reasons on initial 
			page load, this request should be 
			sent only when it's needed. 
		*/

		if(model.countryList.length > 0){
			typeof callback === 'function' && callback();
			return;
		}

		this.sendRequest("GetKeyValueTable", null, data => onSuccessResponse.call(this, data));

		function onSuccessResponse(action){
			handleGetKeyValueTable.call(this, action, () => {
				var defaultPCode = 356;
				model.guestPCode = model.countryList.find(item => {
					return item.cCode.toUpperCase() === (model.guestCountry || '').toUpperCase();
				});
				if(!model.guestPCode) model.guestPCode = defaultPCode;
				else model.guestPCode = model.guestPCode.pCode;
				typeof callback === 'function' && callback();
				model.dispatchEvent('GetKeyValueTableComplete');
			});
		}
	},
	handleSBActivateBonus(action){
		this.showClientMessage(action);
	},
	handle_netEntLogin(){
		this.userBusiness().then(module => module.handle_netEntLogin.apply(this, arguments));
	},
	handleBetsoftStart(){
		this.userBusiness().then(module => module.handleBetsoftStart.apply(this, arguments));
	},
	handle_GameLog(){
		this.userBusiness().then(module => module.handle_GameLog.apply(this, arguments));
	},
	handle_bonusPointMultiplier(){
		this.userBusiness().then(module => module.handle_bonusPointMultiplier.apply(this, arguments), false);
	},
	createQSForRegister(){
		let qs = getLocationSearch();

		qs['client_ip'] = model.ip;
		qs['user-agent'] = window.navigator.userAgent.replaceAll('=', '');
		qs['channelId'] = model.channelId;

		Array.isArray(model.qsTargetCookies) && model.qsTargetCookies.forEach(paramName => {
			let val = cookieHelper.get(paramName);
			if(val) qs[paramName] = val;
		});

		let utmParams = localStorage.getItem('saved_utm_params');
		if(utmParams){
			utmParams = JSON.parse(utmParams);
			Object.keys(utmParams).forEach(key => qs[key] = utmParams[key]);
		}

		let qsVal = addQueryStringValues('', qs);
		if(qsVal.charAt(0) == '?') qsVal = qsVal.substring(1, qsVal.length);

		return {qsVal, qsObj: qs};
	},
	configurePiqViaCashier(){
		return new Promise(resolve => {
			this.userBusiness().then(module => {
				module.configurePiqViaCashier.apply(this, arguments);
				resolve();
			});
		});
	},
};

function setupDomainAndLangEvents(){
	for(let key in model.supportedLangs){
		let val = model.supportedLangs[key];
		if(!val.urlPath) val.urlPath = val.ccode || key;
	}

	let domain = this.getPaths()[model.subDomainPathIndex];

	if(domain){
		let lang = Object.keys(model.supportedLangs).find(langKey => {
			let lang = model.supportedLangs[langKey];
			if(lang.urlPath.toLowerCase() === domain.toLowerCase()) return true;
			return langKey.toLowerCase() === domain.toLowerCase();
		});

		if(!lang) domain = null;
	}

	model.subDomain = domain;
	model.subDomain = model.subDomain ? model.subDomain.toUpperCase() : null;

	configureLangEvents.call(this);
}

function configureLangEvents(){
	if(typeof model.startingLang !== 'string'){
		for(let key in model.supportedLangs){
			let val = model.supportedLangs[key];
			
			if(model.subDomain === key.toUpperCase() || model.subDomain === (val.urlPath + '').toUpperCase()){
				model.startingLang = key;
				break;
			}
		}
	}

	let langObs = reactive({
		supportedLangs: (() => {
			var arr = [];
			var i = 0; 
			for(var key in model.supportedLangs){
				var item = model.supportedLangs[key];
				item.flagValueObs = null;
				item.pos = i;
				item.key = key;
				if(typeof item.asPath !== 'boolean') item.asPath = true;
				arr.push(reactive(item));
				i++;
			}
			return arr;
		})(),
		langCaption: '',
		selLangItem: null,
		selectedLang: null,
	});

	model.langComponentObs = langObs;

	watch(() => langObs.selectedLang, value => setupAndSaveLang.call(this, value));

	var urlParamLang = getParameterByName('lang');
	if(urlParamLang){
		langObs.selectedLang = urlParamLang;
		clearLocationSearch('lang');
	}
	else langObs.selectedLang = getDefaultLang.call(this);

	setupAndSaveLang.call(this, langObs.selectedLang);

	model.on('UserProfileLoaded', 'GetPlayerInfoLoaded', () => {
		if(!model.personalSettings) return;
		if(model.personalSettings.lang) langObs.selectedLang = model.personalSettings.lang;
	});

	model.on('LoginComplete', 'LogoutComplete', () => renderSupportedLangsByDomain.call(this));

	model.on('SetLang', () => setupLang.call(this, model.langKey));
		
	renderSupportedLangsByDomain.call(this);
	setupLang.call(this, model.langKey);

	model.observables.navCountrySelectionShown = !model.langAsPath && !localStorage.getItem('ignore-init-country-selection') && Object.keys(model.countryRegionMap || {}).length > 0;

	function setupAndSaveLang(value){
		let prevLang = model.langKey;
		if(setupLang.call(this, value)){
			this.saveChosenLang(model.langKey).then(() => {
				if(prevLang && prevLang !== model.langKey && model.isUI('FUND')) nextTick(() => {
					model.dispatchEvent('LangSaved', {prevLang});
				});
			});
			model.dispatchEvent('SetLang');
		}
		else langObs.selectedLang = getDefaultLang.call(this);
	}

	function getDefaultLang(){
		return model.startingLang || Enum.DEFAULT_LANG_VAL;
	}

	function setupLang(value){
		var targetLang = langObs.supportedLangs.find(item => item.key == value && item.displayed);
		if(!targetLang) return null;

		langObs.langCaption = targetLang.langFull;
		langObs.selLangItem = targetLang;
		model.lang = targetLang.langValue;
		model.langKey = value;
		model.language = targetLang.language;
		model.langCCode = targetLang.ccode;
		model.langUrlPath = targetLang.urlPath;
		model.langAsPath = targetLang.asPath;
		model.langISOCodesByCase = targetLang.langISOCodesByCase || null;

		let prevSubDomain = model.subDomain;

		model.subDomain = (model.langUrlPath || model.langKey).toUpperCase();

		var paths = this.getPaths();
		paths.includes((prevSubDomain + '').toLowerCase()) && paths.splice(model.subDomainPathIndex, 1);
		if(model.langAsPath) {
			paths.splice(model.subDomainPathIndex, 0, model.subDomain.toLowerCase());
		}
		this.updatePathname(paths);

		return targetLang;
	}

	function renderSupportedLangsByDomain(){
		for(var key in model.supportedLangs){
			var lang = model.supportedLangs[key];

			var fv = lang.flagValue;
			if(isObject(fv)) fv = fv[getKeyByValue(Enum.SiteIDs, model.siteId)];
			!fv && (fv = lang.flagValue);
			lang.flagValueObs = fv;

			var ind = langObs.supportedLangs.findIndex(x => x.key == key);

			if(lang.displayed && ind === -1) langObs.supportedLangs.push(lang);
			else if(!lang.displayed && ind !== -1) langObs.supportedLangs.splice(ind, 1);
		}
		langObs.supportedLangs.sort((item1, item2) => item1.pos - item2.pos);
	}
}

function requestUserGuestCountry(){
	if(isObject(model.fakeGeoIPResponse)){
		setTimeout(() => handleResponse.call(this, model.fakeGeoIPResponse), 100);
		return;
	}

	if(typeof model.urls.geoIP === 'string'){
		this.webServiceSend({
			type: 'GET',
			dataType: 'json',
			url: model.urls.geoIP,
			success: data => handleResponse.call(this, data),
		});
	}

	function handleResponse(data){
		data.ip && (model.ip = data.ip);
		model.guestCountry = (data.cCode || data.country || '').toUpperCase();
		model.loginRestricted = model.restrictLoginByCC.includes(model.guestCountry);

		var restrictPath = model.restrictedRegions[model.guestCountry];
		if(sessionStorage.getItem('bypass-country-check') == 'true' || !restrictPath || this.getPaths().includes(restrictPath)){
			model.observables.guestCountryInit = true;
			return;
		}

		var redirUrl = null;
		var subDomain = (model.subDomain || '').toLowerCase();
		redirUrl = addDirPath(window.location.origin, subDomain, restrictPath) + window.location.search;

		location.replace(redirUrl);
	}
}

function handleGetKeyValueTable(action, callback) {
	if(!action){
		typeof callback === 'function' && callback();
		return;
	}

	var main = arrayFirst(action.messages.message, msg => msg.type == 'Main');
	if(!main?.table?.response?.values) return;

	model.countryList = toArray(main.table.response.values.keyVal);
	model.countryList.sort((a, b) => {
		if(a.value < b.value) return -1;
		if(a.value > b.value) return 1;
		return 0;
	});

	typeof callback === 'function' && callback();
}

function onPageReload() {
	model.isPageLoad = true;

	this.sendLoadUser(() => {
		this.sendGetPlayerInfo([
			{
				firstCall: model.isPage(Enum.Pages.PROMO_INFO),
			},
			'brokenGame', 
			'playerInfo', 
			'mainAccount', 
			'paymentMethods', 
			'withdraw', 
			'deposit', 
			'personalSettings', 
			{
				propName: 'promotions',
				params: model.gpiAsLogin && model.wasTPFetched && {
					asLogin: true,
				},
			}, 
			'termsAcceptance',
			'playerUI',
		]).then(async args => {
			await this.handle_login(args.action, args.xml);
			this.sendPromoListRequest();
			model.isPageLoad = false;
		});
	});
}

function saveInfoFromQS(){
	if(model.recommendationCode){
		setLocationSearch({
			'utm_source': 'referral',
			'btag': 'referral',
			'utm_campaign': model.recommendationCode,
		});
	}

	let utmParams = getParameterByName(x => x.includes('utm_'));
	if(Object.keys(utmParams).length > 0) {
		for(let key in utmParams) utmParams[key] = decodeURI(utmParams[key]);
		localStorage.setItem('saved_utm_params', JSON.stringify(utmParams));
		clearLocationSearch(x => x.includes('utm_'));
	}

	/* 
		These variables should be set on all views.
	*/
	this.setupQueryValueCookie('btag', model.btagDuration, false);
	this.setupQueryValueSessionItem('clickId');
	this.setupQueryValueSessionItem('sspdata');

	/*
		PromoCode from url should 
		always be specified. 
		*/
	this.setupPromoCodeFromUrl();
}