export const ReceiptOfYourPaymentText = {
    en: 'Receipt',
    es: 'Recibo',
};

const waitingInput = {
    en: 'Pending transaction.',
    es: 'Transacción pendiente.'
};

const authorizeFailed = {
    en: 'Responsible gambling limit breached.',
    es: 'DEVUELTO: Se ha superado el límite de juego responsable',
};

const errorLimit = {
    es: 'Devuelto: Fuera de límites',
};

const transactionExpired = {
    es: 'Cancelada: Transacción expirada.',
};

const declinedGeneric = {
    en: 'Declined',
    es: "La tarjeta ha sido rechazada, inténtalo con una distinta."
};

export default {
    status: {
        en: 'status',
    },
    ReceiptOfYourPaymentText,
    SUCCESS: {
        en: 'Success',
        es: 'Exitoso',
    },
    AUTHORIZE_FAILED: authorizeFailed,
    AUTHORIZE_FAILED_LIMIT_BROKEN: authorizeFailed,
    WAITING_INPUT: waitingInput,
    PENDING_BY_PROVIDER: waitingInput,
    ERROR_BELOW_LIMIT: errorLimit,
    ERROR_ABOVE_LIMIT: errorLimit,
    AUTHORIZE_FAILED_USER_NOT_VALIDATED: {
        es: 'El usuario no está validado',
    },
    AUTHORIZE_FAILED_PAYMENT_DISABLED: {
        es: 'pago deshabilitado',
    },
    AUTHORIZE_FAILED_WITHDRAW_NOTALLOWED: {
        es: 'retirar no permitido',
    },
    AUTHORIZE_FAILED_DEPOSIT_NOTALLOWED: {
        es: 'depósito no permitido',
    },
    ERR_DECLINED_OTHER_REASON: declinedGeneric,
    ERR_DECLINED_NO_FUNDS: {
        en: 'Insufficient funds',
        es: 'Rechazado: Fondos insuficientes.',
    },
    ERR_DECLINED_LIMIT_OVERDRAWN: {
        en: 'Out of limits',
        es: 'Rechazado: Fuera de límites.',
    },
    FAILED_ON_PROVIDER: declinedGeneric,
    ERR_CANCELLED_BY_MERCHANT: transactionExpired,
    CANCELLED_BY_PSP: transactionExpired,
    TRANSACTION_EXPIRED: transactionExpired,
    MaskedAccount: {
        es: 'Cuenta',
    },
    TransactionAmount: {
        en: 'Transaction amount',
        es: 'Monto de la transacción',
    },
    CANCELLED_BY_USER: {
        es: 'Cancelado por el usuario',
    },
    SUCCESS_WITHDRAWAL_APPROVAL: {
        es: 'Retiro exitoso',
    },
    ERR_DECLINED_FRAUD: declinedGeneric,
    ERR_DECLINED_EXPIRED_CARD: {
        en: 'Please check the Expiration Date for your card',
        es: 'Por favor verifique la fecha de vencimiento de su tarjeta',
    },
    ERR_CANCELLED_BY_PSP: {
        en: 'Transaction failed, please try again later',
        es: 'La transacción falló, inténtalo de nuevo más tarde',
    },
}