import { addFullscreenExitCallback, getParameterByName, isObject } from "@helpers/utils.js"
import { reactive, nextTick } from "vue"
import controller from "./controller/controller.js";

import ModalHandler from "./modal-handler.js";
import model from "./model.js";
import Enum from "@shared/enum.js";
import NetworkError from "@components/network-error-view.vue";
import UpperNotification from "@components/upper-notification-view.vue";
import modalHeaderTemplate from "@components/modal/modal-header-template.vue";

export default {
	components: { NetworkError, UpperNotification, modalHeaderTemplate },
	mixins: [ModalHandler],
	created(){
		model.vue.root = this;

		(() => {
			let $doc = $(document);
			$doc.on('scroll', () => this.scrollPosObs = $doc.scrollTop());
		})();

		addFullscreenExitCallback(fsActive => this.inFS = fsActive);

		updateCurrCatIDs.call(this);
		model.on('CurrCatIDs', () => updateCurrCatIDs.call(this));

		function updateCurrCatIDs(){
			Object.keys(model.currCatIds).forEach(propName => {
				this.currCatIds[propName] = model.currCatIds[propName];
			});
		}
			
		model.on('NetworkError', () => this.networkErrDisplayed = model.networkError);

		this._initModalHandler();

		!model.isMobile && model.on('WindowResize', (e) => {
			var isMobileMode = window.innerWidth < 1024;
			this.mobileMode = isMobileMode;
			!isMobileMode && model.dispatchEvent('HideMobileNav');
		});

		model.on('LoginComplete', 'LogoutComplete', (e) => {
			this.siteId = model.siteId;
			if (getParameterByName('avoidnavstatuschange') != 'true') {
				this.userAuthStatus = Enum.UserAuthStatuses[model.logged ? 'LOGIN' : 'LOGOUT'];
			}
			this.userLogged = model.logged;
			this.userValidated = model.userValid();
			this.userActive = model.userActive();
			this.renderValidationUI = getParameterByName('validreqtest') == 'true' || (model.userActive() && !model.accDisabled() || model.notValidByReasonStatusId());
			for (var key in this.supportInfo) this.supportInfo[key] = model.supportInfo[key];
			this.userProductId = Boolean(model.userProfile) && model.userProfile.productId;
		});

		model.on('GetPlayerInfoLoaded', 'UserProfileLoaded', 'SetLang', 'LogoutComplete', (e) => {
			this.userCurrency = (model.getCurrency());
			this.uiType = (model.uiType);
			this.userProfile = (model.userProfile || {});
			model.observables.userCountry = model.userProfile?.country;
		});

		var onSetLang = () => {
			this.lang = model.lang;
			this.langKey = model.langKey;
			this.language = model.language;
		}

		onSetLang();
		model.on('SetLang', onSetLang);

		(() => {
			let preloadTimeout;

			this.preloaderDisplayed = shouldBeDisplayed.call(this);

			model.on('ShowPreloader', () => {
				clearTimeout(preloadTimeout);
				this.preloaderDisplayed = shouldBeDisplayed.call(this);
			});

			model.on('HidePreloader', () => {
				clearTimeout(preloadTimeout);
				preloadTimeout = setTimeout(() => {
					if(!shouldBeDisplayed.call(this)) this.preloaderDisplayed = false;
				}, 100);
			});

			function shouldBeDisplayed(){
				if(model.observables.selectedMiniGame) return false;

				return (
					model.ajaxPreloading || 
					model.pendingScripts > 0 || 
					model.dynamicImports > 0
				)
			}
		})();

		model.on('OpenPage', () => model.observables.activePage = model.observables.activePage);
		model.on('MobileSideMenuActive', props => this.mobileSideMenuActive = props.active);

		this.$watch(() => this.backdropContentVisible, bool => {
			model.dispatchEvent('BackdropContentVisible', 'ForceResize', {bool});
		});
	},
	data(){
		return {
			appViewInfo: model.compInfo,
			modalDisplayed: false,
			limitsFormIncluded: model.limitsFormIncluded,
			hasProviderName: Boolean(model.thProviderName),
			spaceBusAmounts: model.spaceBusAmounts,
			hideCMGameLoad: model.hideCMGameLoad,
			view: model.view,
			UIID: model.UIID,
			siteId: model.siteId,
			uiType: model.uiType,
			isMobile: model.isMobile,
			isIOS: model.isIOS,
			inboxActive: model.inboxActive,
			filterActive: model.filterActive,
			maxScreenHeight: `${model.maxScreenHeight}px`,
			activePage: model.observables.activePage,
			mobileMode: model.isMobile,
			userCurrency: model.getCurrency(),
			currCatIds: {},
			supportInfo: (supportInfo => {
				var obj = {};
				for (var key in supportInfo)
					obj[key] = supportInfo[key];
				return obj;
			})(model.supportInfo),
			modals: controller.registeredModals.value,
			fixedTop: {value: 0},
			preloaderDisplayed: false,
			userValidated: false,
			userActive: false,
			renderValidationUI: false,
			userProductId: false,
			userLogged: false,
			userAuthStatus: Enum.UserAuthStatuses.UNDETERMINED,
			lang: '',
			langKey: '',
			language: '',
			networkErrDisplayed: false,
			mobileSideMenuActive: false,
			inFS: false,
			userProfile: {},
			modalVisible: false,
			gameDisplayed: false,
			filterDisplayed: false,
			anyBannerPages: false,
			scrollPosObs: 0,
		};
	},
	computed: {
		backdropContentVisible() {
			var modalVisible = this.modalDisplayed;
			var gameDisplayed = model.observables.gameDisplayedObs;
			var filterDisplayed = model.observables.filterDisplayed;

			if (model.isUI('FUND') || model.isApp) {
				if (model.isMobile) return !modalVisible && !filterDisplayed && !gameDisplayed;
				return !gameDisplayed;
			}

			if (model.isMobile) return !modalVisible && !gameDisplayed;
			return !gameDisplayed;
		},
		firstDepCompleted () {
			return model.observables.deposits > 0;
		},
	},
	methods: {
		hasPromoStarted(){
			return EGOE.hasPromoStarted.apply(this, arguments);
		},
		updatePaddingTop() {
			function deductAppAdvert(){
				let $headerAdvert = $('.app-advert.on-header');
				return (model.vue.root.fixedTop.value - ($headerAdvert.length > 0 ? $headerAdvert.outerHeight(true) : 0) + 'px');
			}
			if (model.isUI('FOUR_SG')) {
				return model.vue.root.fixedTop.value + 'px';
			}
			if (model.isUI('FUND')) {
				if(model.isMobile && model.observables.filterDisplayed){
					return (model.vue.root.fixedTop.value + 80) + 'px';
				}

				if(model.appAdvert){
					if(model.observables.activePage === Enum.Pages.WEBRES){
						if(model.observables.subNavPlaceholderSlim) return deductAppAdvert();
					}
					else if(model.observables.activePage === Enum.Pages.PROMO_INFO || model.isView(Enum.Views.CRM)) return deductAppAdvert();
				}

				if(model.observables.subNavPlaceholderSlim && !model.observables.filterDisplayed) return '0px';

				if(model.isView(Enum.Views.MANIFIESTO)) return (model.vue.root.fixedTop.value + 'px');

				return ![
					Enum.Pages.MAINHOME,
					Enum.Pages.PROMO_INFO,
					Enum.Pages.PAGE_NOT_FOUND,
				].includes(model.observables.activePage) ? (model.vue.root.fixedTop.value + 'px') : 0;
			}
			if (model.isUI('SKY')) {
				if(model.isApp) {
					if(model.observables.activePage !== Enum.Pages.LOBBY){
						return model.vue.root.fixedTop.value + 'px';
					}
					return '0px';
				}
				return !model.vue.root.anyBannerPages ? (model.vue.root.fixedTop.value + 'px') : '0px';
			}
			return null;
		},
		extend(){
			return $.extend.apply($, arguments);
		},
		debug(arg){
			console.log(arg);
		},
		createLangObs(comp, obj, forceAsObj) {
			if (typeof forceAsObj !== 'boolean')
				forceAsObj = false;

			var isObj = forceAsObj || isObject(obj);

			let obs = reactive({value: null});
			obs.value = isObj ? model.redeemLangObj(obj) : model.getStr(obj);

			nextTick(() => {
				let cbx = model.on('SetLang', () => {
					obs.value = (isObj ? model.redeemLangObj(obj) : model.getStr(obj))
				});
			});

			return obs;
		},
		changeExtToWebP(img) {
			return controller.changeExtToWebP(img);
		},
		checkUserAuthStatus(status) {
			return this.userAuthStatus == status;
		},
		goTo(uiContext, removePrevModals) {
			model.observables.userInteracted = true;
			return controller.goTo(uiContext, removePrevModals);
		},
		gotoCategory(ids) {
			return controller.gotoCategory(ids);
		},
		goToPromo(uiContext) {
			return controller.loadPromoWin(uiContext);
		},
		getCurrency(code) {
			return model.currencies[('' + code).toUpperCase()] || {};
		},
		getStr(label) {
			return model.getStr(label);
		},
		formatAsCurrency(params) {
			return model.formatAsCurrency(params);
		},
		openFooterLink(id, label) {
			return () => {
				controller.openFooterLink(id, label);
			};
		},
		startGame(gameID) {
			controller.startGame({
				gameID: gameID,
				mode: 'fun',
			});
		},
		displayHtmlContent(title, htmlContent) {
			return controller.displayHtmlContent(title, htmlContent);
		},
		isUI(key) {
			return model.isUI(key);
		},
	},
}